<template>
  <div>
    <v-autocomplete
      :required="required"
      :prepend-icon="!hideIcon ? 'mdi-domain' : ''"
      density="compact"
      @update:model-value="updateValue"
      :model-value="modelValue"
      @keydown.enter.prevent="() => {}"
      @update:search="onSearchInput"
      :items="companies"
      data-testid="select-company-search-field"
      ref="companySelect"
      item-value="id"
      return-object
      no-filter
      :hide-no-data="!filters.searchStr"
      persistent-hint
      :label="placeholder"
      :loading="isLoading"
      :disabled="disabled">
      <template v-slot:selection="{ item }">[{{ item.raw.scac }}] {{ item.raw.name }}</template>

      <template v-slot:item="{ item, props }">
        <v-list-item v-bind="props" :title="null" role="option">
          <v-list-item-title v-html="`${item.raw.name}`"></v-list-item-title>
          <v-list-item-subtitle>SCAC: {{ item.raw.scac }}</v-list-item-subtitle>
        </v-list-item>

        <v-divider
          v-if="companies.indexOf(item.raw) < companies.length - 1"
          :key="companies.indexOf(item.raw)"></v-divider>
      </template>

      <template v-slot:append-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-title>
            <v-btn color="primary" block @click="openCompanyDialog">
              Create a new Carrier Company
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template #label v-if="required">
        {{ placeholder }}
        <span class="text-red"><strong>*</strong></span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { useFlag } from '@unleash/proxy-client-vue';

import selectMixin from '@satellite/components/mixins/selectMixin';
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import { emitCustomEvent } from '@/composables/useCustomEvent';

/**
 * Company Select
 * @displayName Company Select
 */
export default {
  setup() {
    const useSearchExpanded = useFlag('enable-company-autocomplete-v2');
    return { useSearchExpanded };
  },
  mixins: [selectMixin, dataListMixin],
  props: {
    /**
     * @model
     */
    modelValue: {
      type: Object,
      required: false
    },
    /**
     * Is input disabled
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Input placeholder
     */
    placeholder: {
      type: String,
      required: false,
      default: 'Start Typing a company name, scac, usdot, or mc'
    },
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      companies: [],
      filters: {
        searchStr: null
      },
      searchFields: ['name', 'scac', 'mc', 'usdot'],
      isLoading: false
    };
  },
  methods: {
    /**
     * V-model
     * @public
     * @param companyId
     */
    updateValue(company) {
      /**
       * Emits input with company Id
       * @event input
       * @property {string} companyId
       */
      this.$emit('update:model-value', company);
    },
    /**
     * Retrieves data when filters are updated
     * @public
     */
    async getData() {
      this.isLoading = true;

      if (!this.filters.searchStr) {
        this.isLoading = false;
        return (this.companies = []);
      }

      const { items } = await this.services.company.autocomplete({
        limit: 15,
        search: this.filters.searchStr ?? '',
        useSearchExpanded: this.useSearchExpanded
      });
      this.companies = items;
      this.isLoading = false;
    },
    openCompanyDialog() {
      emitCustomEvent('open-create-carrier-company-dialog', {
        mixpanelEntryPoint: this.entryPoint,
        onCompanyCreated: this.handleCompanyCreated
      });
    },
    /**
     * Handles new company creation
     * @public
     * @param {Company} company
     */
    handleCompanyCreated(company) {
      this.$emit('companyChanged', company);
      this.companies.push(company);
      this.updateValue(company);
      this.$refs.companySelect.isMenuActive = false;
    },
    getSelectedItem() {
      return this.$refs.companySelect.selectedItem;
    },
    emitCompanyChanged(value) {
      const newCompany = this.companies.find(company => company.id === value);
      this.$emit('companyChanged', newCompany);
    },
    onSearchInput(inputVal) {
      this.filters.searchStr = inputVal;
    }
  },
  async mounted() {
    // If there is already a value set we need to fetch the company record and add it to our companies list
    // By default this component does not have any companies until they are searched for
    if (this.value && !this.companies.length) {
      await axios.get(`/company/${this.modelValue}`).then(response => {
        this.companies.push(response.data.data);
      });
    }
  }
};
</script>
