import { useCustomEventSubscription } from './useCustomEventSubscription';
import { useModal } from '@/composables';
import type { CustomEventMap } from './useCustomEvent';

interface DialogEventHandlerOptions<K extends keyof CustomEventMap> {
  eventName: K;
  transformEventData?: (data: CustomEventMap[K]['detail']) => any;
}

export function useEventBasedDialog<K extends keyof CustomEventMap>({
  eventName
}: DialogEventHandlerOptions<K>) {
  const { modal: isOpen, openModal, closeModal } = useModal(false);

  const { eventData } = useCustomEventSubscription(eventName, () => {
    openModal();
  });

  const closeDialog = async () => {
    closeModal();
  };

  return {
    isOpen,
    dialogProps: eventData,
    closeDialog
  };
}
