<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Change user password"
    :scrollable="false"
    loading-message="Updating..."
    @close="close"
    @confirm="$refs.changePasswordForm.submit()">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$slots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <template #body>
      <change-password-form @close="close" ref="changePasswordForm"></change-password-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin]
};
</script>
