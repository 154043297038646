<template>
  <v-text-field
    density="compact"
    :class="{ required: required }"
    :model-value="modelValue"
    @update:modelValue="value => $emit('update:model-value', value)"
    v-bind="{ ...$props, ...$attrs }"
    :placeholder="placeholder"
    @wheel="handleWheelEvent"
    :rules="computedRules"
    validate-on="blur">
    <template v-slot:label v-if="tooltipText">
      <div class="with-tooltip">
        {{ label }}
        <help-icon-tooltip iconStyleClass="grey darken-2">{{ tooltipText }}</help-icon-tooltip>
      </div>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    /**
     * @model
     */
    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: String,
      required: false,
      default: '20px'
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    computedRules() {
      return this.required ? [...this.rules, this.$validator.rules.required()[0]] : this.rules;
    }
  },
  methods: {
    handleWheelEvent(e) {
      if (this.$attrs.type === 'number') {
        e.currentTarget.blur();
      }
    }
  }
};
</script>
