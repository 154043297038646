<template>
  <dialog-base
    class="create-appointment-dialog is-relative"
    content-class="create-appointment-dialog"
    :key="renderKey"
    :loading="false"
    height="85vh"
    v-bind="$props"
    header="Create an Appointment"
    loading-message="Creating appointment..."
    @close="close"
    max-width="100%"
    :value="showDialog"
    width="1100px">
    <template #activator="slotProps" v-if="!externalActivator">
      <v-tooltip location="top" :disabled="$rolePermissions.canCreateAppointment">
        <template #activator="{ props }">
          <div v-bind="props">
            <slot
              v-if="$slots.activator"
              name="activator"
              v-bind="{ ...slotProps, isButtonDisabled }"></slot>
            <primary-button v-else :disabled="isButtonDisabled" v-bind="slotProps">
              {{ buttonLabel || 'Add Appointment' }}
            </primary-button>
          </div>
        </template>
        <span>{{ novaCore.getRoleActionError() }}</span>
      </v-tooltip>
    </template>
    <template #body>
      <create-appointment-form
        @close="close"
        @scheduled="emitScheduledAppointment"
        :context="context"
        :follow-to-appointment-details="followToAppointmentDetails"
        :asset-visit="assetVisit"></create-appointment-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Dialog for create appointment form
 * @displayName Create Appointment Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Calendar context when dayview is clicked
     * contains date, time, warehouse, and dock
     */
    context: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    assetVisit: {
      type: Object,
      required: false
    },
    buttonLabel: {
      type: String,
      required: false,
      default: null
    },
    /*
     * If true, the form will follow to the appointment details page after creating an appointment
     */
    followToAppointmentDetails: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isButtonDisabled() {
      return (
        !this.$me ||
        this.novaCore.isInternalUser(this.$me) ||
        this.readOnly ||
        !this.$rolePermissions.canCreateAppointment ||
        this.disableActivator
      );
    }
  },
  methods: {
    emitScheduledAppointment(appointment) {
      this.$emit('scheduled', appointment);
    }
  }
};
</script>
