<template>
  <v-container class="fill-height justify-center">
    <div>
      <v-row no-gutters class="flex-column align-center" data-testid="no-results-message-container">
        <v-icon class="mr-2 text-primary mb-3" size="x-large">mdi-laptop</v-icon>
        <strong class="no-results-message">Looks like this page is not ready for mobile</strong>
        <span class="px-4 text-center pt-3">
          Please access this page through a computer or tablet for a better experience
        </span>
        <outline-button class="mt-4" to="/appointments">Go To Appointments Page</outline-button>
      </v-row>
    </div>
  </v-container>
</template>
<script>
export default {};
</script>
