import _ from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useEventHub } from '@/composables/index.js';

export default function useSettings() {
  const store = useStore();
  const eventHub = useEventHub();
  const settingsStateRef = computed(() => _.get(store, 'state.Settings'));
  const settingsGettersRef = computed(() =>
    _.mapKeys(
      _.pickBy(_.get(store, 'getters'), (value, key) => key.startsWith('Settings/')),
      (value, key) => key.replace(/^Settings\//, '')
    )
  );
  const settingsActionsRef = computed(() =>
    _.mapKeys(
      _.pickBy(_.get(store, '_actions'), (value, key) => key.startsWith('Settings/')),
      (value, key) => key.replace(/^Settings\//, '')
    )
  );
  const settingsMutationsRef = computed(() =>
    _.mapKeys(
      _.pickBy(_.get(store, '_mutations'), (value, key) => key.startsWith('Settings/')),
      (value, key) => key.replace(/^Settings\//, '')
    )
  );
  /**
   * Add Subspace Event Logging Listener
   * Prints event to console
   * @public
   */
  const addSubspaceLoggingEventListener = () => {
    eventHub.$on('subspace-event', data => {
      console.log(
        `%c SUBSPACE EVENT ----- ${data.event.action} ${data.event.entity}`,
        'background: #FFEA61; color: #000',
        data.payload
      );
    });
  };
  /**
   * Remove Subspace Event Logging Listener
   */
  const removeSubspaceLoggingEventListener = () => {
    eventHub.$off('subspace-event');
  };

  return {
    settingsStateRef,
    settingsGettersRef,
    settingsActionsRef,
    settingsMutationsRef,
    addSubspaceLoggingEventListener,
    removeSubspaceLoggingEventListener
  };
}
