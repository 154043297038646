import { getResponse } from '@satellite/services/serviceUtilities';

export default {
  /**
   *
   * @param params
   * @param options { fields: string[], joins: string[], includeMetaData: boolean }
   * @param requestConfig
   * @returns {Promise<Object|*>}
   */
  async getCustomFormData(params, options = {}, requestConfig = {}) {
    try {
      const response = await axios.get('custom-forms/form-data', {
        params,
        ...requestConfig
      });
      return getResponse(response, options) || [];
    } catch (error) {
      console.error('Error fetching custom form data:', error);
      return [];
    }
  },
  /**
   * Get loadtype triggers
   * @param loadTypeId
   * @param params
   * @param options
   * @param requestConfig
   * Returns Full join structure eagerly loaded
   * [ {...trigger: { flow: { fromFrom: { formFields: [{ field: customField }] } } } ]
   * */
  async getLoadTypeTriggers(loadTypeId, searchParams, options = {}, requestConfig = {}) {
    try {
      const response = await axios.get('custom-forms/trigger', {
        params: {
          s: {
            objectId: loadTypeId,
            entityName: 'loadtype',
            flowId: { $notnull: true },
            ...searchParams
          },
          limit: 1000
        },
        requestConfig
      });
      return getResponse(response, options) || [];
    } catch (error) {
      console.error('Error fetching loadtype triggers:', error);
      return [];
    }
  },
  /**
   * Get warehouse triggers
   * @param warehouseId
   * @param params
   * @param options
   * @param requestConfig
   * Returns Full join structure eagerly loaded
   * [ {...trigger: { flow: { fromFrom: { formFields: [{ field: customField }] } } } ]
   * */
  async getWarehouseTriggers(warehouseId, searchParams, options = {}, requestConfig = {}) {
    try {
      const response = await axios.get('custom-forms/trigger', {
        params: {
          s: {
            objectId: warehouseId,
            entityName: 'warehouse',
            flowId: { $notnull: true },
            ...searchParams
          },
          limit: 1000
        },
        requestConfig
      });
      return getResponse(response, options) || [];
    } catch (error) {
      console.error('Error fetching warehouse triggers:', error);
      return [];
    }
  }
};
