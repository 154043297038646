<template>
  <div>
    <vue-tel-input
      ref="telInput"
      :class="{ 'has-error': errorMessage }"
      :value="modelValue"
      :model-value="modelValue"
      auto-format
      @input="onInput"
      valid-characters-only
      :inputOptions="{ placeholder: placeholder }"
      v-on:country-changed="validateInput"
      :defaultCountry="defaultCountry"
      mode="international"
      :style="style"
      @blur="validateInput"
      @validate="setIsValid"
      :preferred-countries="preferredCountries">
      <template #label v-if="required">
        {{ label }}
        <span class="text-red">*</span>
      </template>
      <template v-slot:label v-if="tooltipText">
        <div class="with-tooltip">
          {{ label }}
          <help-icon-tooltip iconStyleClass="grey darken-2">{{ tooltipText }}</help-icon-tooltip>
        </div>
      </template>
    </vue-tel-input>
    <span class="text--color-text-error" v-if="errorMessage">{{ errorMessage }}</span>
  </div>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    validator: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: 'Phone Number'
    },
    defaultCountry: {
      type: String,
      required: false,
      default: 'US'
    },
    marginTop: {
      type: String,
      required: false
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Phone Number'
    }
  },
  data: function () {
    return {
      errorMessage: '',
      isMounted: false,
      isValid: false
    };
  },
  computed: {
    preferredCountries() {
      return [
        'US',
        'CA',
        'AU',
        'BE',
        'BR',
        'DE',
        'ES',
        'GB',
        'HU',
        'IE',
        'IT',
        'KR',
        'MT',
        'MX',
        'NL',
        'NZ',
        'PH',
        'PL',
        'PR',
        'PT',
        'SE',
        'SG',
        'TR',
        'UK'
      ];
    },
    activeCountryIso2() {
      return this.$refs.telInput?.activeCountry?.iso2 ?? this.novaCore.DEFAULT_COUNTRY_CODE;
    },
    style() {
      if (this.marginTop) {
        return {
          'margin-top': this.marginTop
        };
      }
      return {};
    }
  },
  methods: {
    setIsValid(val) {
      this.isValid = val.valid;
    },
    validateInput() {
      this.errorMessage = '';
      if (this.isMounted) {
        if (!this.isValid) {
          this.errorMessage = 'Please enter a valid phone number';
        }
        if (this.required & !this.modelValue) {
          this.errorMessage = this.validator.rules.required(this.label)[0]();
        }
      }
    },
    onInput(val) {
      if (this.isMounted) {
        if (typeof val === 'object') {
          return;
        }
        if (val) {
          this.errorMessage = '';
        }
        this.$emit('update:model-value', val);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  watch: {
    defaultCountry(country) {
      if (!this.value) {
        this.$refs.telInput?.choose({ iso2: country });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vue-tel-input {
  margin-top: 12px !important;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 0;

  &:focus-within {
    box-shadow: none !important;
    border-bottom: 2px solid black !important;
  }

  &.has-error {
    border: 1px solid $color-text-error !important;
  }
}
</style>
