<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Create Organization"
    loading-message="Creating Org..."
    @close="close"
    @confirm="$refs.createOrgForm.submit()">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button large v-bind="slotProps" class="mr-2">Create Organization</primary-button>
    </template>
    <template #body>
      <create-org-form
        :redirect-path="redirectPath"
        @close="close"
        ref="createOrgForm"></create-org-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for create org form
 * @displayNAme Create Org Dialog
 */
export default {
  name: 'create-org-dialog',
  mixins: [dialogMixin],
  props: {
    redirectPath: {
      type: String,
      required: false
    }
  }
};
</script>
