import { ref, onMounted, onUnmounted } from 'vue';
import { subscribeToCustomEvent, unsubscribeFromCustomEvent } from './useCustomEvent';
import type { CustomEventMap } from './useCustomEvent';

/**
 * Subscribes to a custom event and returns the data from the event.
 *
 * @param eventName The name of the event to subscribe to. It must be prefixed with `od:` and be declared in the `DocumentEventMap`.
 * ```
 */
export function useCustomEventSubscription<K extends keyof CustomEventMap>(
  eventName: K,
  callback?: (event: CustomEventMap[K]) => void
) {
  const eventData = ref<CustomEventMap[K]['detail']>();

  const handleEvent = (event: CustomEventMap[K]) => {
    eventData.value = event.detail ?? undefined;
    callback?.(event);
  };

  onMounted(() => {
    subscribeToCustomEvent(eventName, handleEvent);
  });

  onUnmounted(() => {
    unsubscribeFromCustomEvent(eventName, handleEvent);
  });

  return {
    eventData
  };
}
