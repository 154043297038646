<template>
  <notifications
    :ignore-duplicates="true"
    animation-type="velocity"
    :position="position"
    v-bind="$attrs">
    <template #body="{ item, close }">
      <div class="app-notification" :class="item.data.appClasses">
        <v-icon class="type-icon">{{ item.data.icon }}</v-icon>
        <p class="text-h6" v-if="item.title">
          {{ item.title }}
        </p>
        <p class="message ma-0 pr-3" v-html="item.text"></p>
        <button class="close" @click="close">
          <v-icon>mdi-close</v-icon>
        </button>
        <a
          class="message ma-0 font-size-x-small font-weight-light"
          v-if="item.data.actionFn && item.data.actionText"
          @click="item.data.actionFn">
          {{ item.data.actionText }}
        </a>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      required: false,
      default: 'bottom center'
    }
  }
};
</script>
