<template>
  <v-dialog
    scrollable
    v-model="dialog"
    v-bind="{ ...$attrs, ...$props }"
    :persistent="persistent"
    :max-width="maxWidth"
    :width="width"
    @keydown.escape="$emit('close')"
    @click:outside="$emit('click:outside')">
    <template #activator="{ props }" v-if="!externalActivator">
      <!--
            @slot Custom activator for the dialog
            @binding {object} slotProps Object containing {on, attrs}
          -->
      <slot name="activator" v-bind="props">
        <primary-button v-bind="props">
          {{ activatorLabel }}
        </primary-button>
      </slot>
    </template>

    <template v-slot:default>
      <slot name="custom-overlay"></slot>

      <v-card
        :data-testid="testId"
        :tile="tile"
        class="dialog-box"
        :class="[
          hasDialogActions ? 'has-dialog-actions' : '',
          { 'pa-0': noPadding },
          ...cardClasses
        ]">
        <v-card-title class="pa-0 d-flex align-center text-h5" v-if="$slots.customHeader">
          <slot name="customHeader"></slot>
        </v-card-title>
        <dialog-header
          v-else
          :header="header"
          @close="close"
          :prepend-header-icon="prependHeaderIcon"
          :hideCloseIcon="hideCloseIcon">
          <template v-slot:header-actions>
            <slot name="header-actions"></slot>
          </template>
        </dialog-header>

        <!-- @slot Dialog body content -->
        <v-card-text class="dialog-content">
          <template v-if="dialogLoading">
            <v-progress-linear indeterminate height="6" class="mt-12"></v-progress-linear>
            <h4 class="text-center mt-4">{{ loadingMessage }}</h4>
          </template>
          <!-- MUST REMAIN V-SHOW or it will cause components in the body that fetch data to mount in an infinite loop -->
          <div v-show="!dialogLoading" class="dialog-body" :class="$props.bodyClass">
            <slot name="body" v-bind="{ dialogActionsRef }"></slot>
          </div>
        </v-card-text>
        <!-- @slot Footer actions -->
        <div v-if="$slots['dialog-actions']" class="card-actions">
          <slot name="dialog-actions"></slot>
        </div>

        <!-- Teleport actions for nested forms -->
        <div
          class="card-actions d-flex"
          ref="dialogActionsRef"
          v-else-if="!$slots['dialog-actions'] && !hideActions">
          <slot name="dialog-actions"></slot>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!! IMPORTANT !!!!!!!!!!!!!!!!!!!!!!!!!!!
 * When a dialog has a form as the body, you'll need to inject the provided dialogActionsRef
 * in the form component inside the setup function
 *
 * e.g.
 *  setup() {
 *     const dialogTeleportTarget = inject('dialogActionsRef');
 *     return { dialogTeleportTarget };
 *   }
 *
 * Then pass it as the teleportTarget prop to the FormBase component.
 * This allows us to have consistent dialog actions
 *
 * e.g.
 * <form-base :teleport-target="dialogTeleportTarget">...</form-base>
 */

import buttonMixin from '../../mixins/buttonMixin';
import DialogHeader from './DialogHeader';
import { ref, provide } from 'vue';

/**
 * Base dialog skeleton used for quick builds of new dialogs
 * @displayName Dialog Base
 */
export default {
  components: { DialogHeader },
  mixins: [buttonMixin],
  props: {
    /**
     * Activator button label
     */
    activatorLabel: {
      type: String,
      required: false
    },
    /**
     * Dialog header
     */
    header: {
      type: String,
      required: true
    },
    /**
     * Show loader
     */
    loading: {
      type: Boolean,
      required: false,
      default: null
    },
    /**
     * Loading message for dialogs containing ajax logic
     */
    loadingMessage: {
      type: String,
      required: false,
      default: 'Loading...'
    },
    /**
     * Show the dialog
     */
    showDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Indicates if external activator should be used
     */
    externalActivator: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Max width of dialog window
     */
    maxWidth: {
      type: String,
      required: false,
      default: '600'
    },
    /**
     * Width of dialog window
     */
    width: {
      type: String,
      required: false,
      default: '600'
    },
    /**
     * Callback that runs when dialog is closed
     */
    closeCallback: {
      type: Function,
      required: false,
      default: () => {}
    },
    hasDialogActions: {
      type: Boolean,
      required: false,
      default: false
    },
    prependHeaderIcon: {
      type: String,
      required: false,
      default: ''
    },
    hideCloseIcon: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    cardClasses: {
      type: Array,
      default() {
        return [];
      }
    },
    tile: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String,
      required: false
    },
    useGlobalLoader: {
      type: Boolean,
      required: false,
      default: true
    },
    persistent: {
      type: Boolean,
      required: false,
      default: true
    },
    bodyClass: {
      type: String,
      required: false
    },
    hideActions: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    dialogLoading() {
      const isGlobalLoading = this.useGlobalLoader ? this.$root.$data.$globalLoading : false;
      const isLoading = this.loading !== null ? this.loading : isGlobalLoading;
      return isLoading;
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    /**
     * Close dialog and run provided callback
     * @public
     */
    close() {
      /**
       * Emits close event
       * @event close
       */
      this.$emit('close');
      this.dialog = false;

      this.closeCallback();
    },
    /**
     * Check if dialog is not on loading state and emits the confirm event
     * @public
     */
    confirm() {
      /**
       * Emits confirm event
       * @event confirm
       */
      if (!this.dialogLoading) {
        this.$emit('confirm');
      }
    }
  },
  setup() {
    const dialogActionsRef = ref(null);

    // Provide it so nested children can `inject` it
    provide('dialogActionsRef', dialogActionsRef);

    return {
      dialogActionsRef
    };
  },
  mounted() {
    this.dialog = this.showDialog;
  },
  watch: {
    showDialog(showDialog) {
      this.dialog = showDialog;
    },
    dialog() {
      if (this.dialog) {
        this.$emit('open');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-content {
  flex: 1;
  overflow: auto;
}

.card-actions {
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 1px solid $color-line-divider;
}
</style>
