import mitt from 'mitt';

const emitter = mitt();
emitter.$on = emitter.on;
emitter.$off = emitter.off;
emitter.$emit = emitter.emit;

export default function useEventHub() {
  return emitter;
}
