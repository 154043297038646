<template>
  <dialog-base
    :key="renderKey"
    :loading="loading"
    v-bind="$props"
    prepend-header-icon="mdi-alert"
    header="Load Type Not Supported"
    loading-message="Updating Appointment Load Type"
    @close="close"
    max-width="100%"
    :show-dialog="showDialog"
    width="500">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button v-bind="slotProps">Update Load Type</primary-button>
    </template>
    <template v-slot:body>
      <template v-if="!loading">
        <p>
          <strong>This appointment's load type is not currently supported at this dock.</strong>
          Select a new load type or reschedule at a different dock
        </p>
        <load-type-select
          v-model="newLoadType"
          :selected-warehouse="eventWarehouse"
          :showOnlyAssignedLoadTypes="true"
          :selected-docks="[eventDock]"></load-type-select>
      </template>

      <div>
        <span class="d-block mb-4">You are about to Reschedule this appointment:</span>
        <div class="d-flex">
          <div class="flex-grow-1">
            <div class="font-weight-bold mb-1">FROM:</div>
            <div class="d-flex flex-column">
              <span>{{ fromDate }}</span>
              <span>{{ fromStart }} - {{ fromEnd }}</span>
              <span>{{ fromDock }}</span>
            </div>
          </div>
          <div class="flex-grow-1">
            <div class="font-weight-bold mb-1">TO:</div>
            <div class="d-flex flex-column">
              <span>{{ toDate }}</span>
              <span>{{ toStart }} - {{ toEnd }}</span>
              <span>{{ toDock }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:dialog-actions>
      <action-group
        v-if="!loading"
        :loading="loading"
        :confirm-label="returnLoadType ? 'Okay' : 'Reschedule'"
        :disable-confirm="!newLoadType"
        @cancel="
          close();
          $emit('cancel');
        "
        @confirm="submitLoadType"></action-group>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import { DateTime } from 'luxon';

export default {
  name: 'LoadTypeReSelectDialog',
  mixins: [dialogMixin],
  props: {
    event: {
      type: Object,
      required: true
    },
    eventWarehouse: {
      type: Object,
      required: true
    },
    eventDock: {
      type: Object,
      required: false
    },
    externalActivator: {
      type: Boolean,
      required: false,
      default: true
    },
    originalEvent: {
      type: Object,
      required: true
    },
    originalEventDock: {
      type: Object,
      required: true
    },
    returnLoadType: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    fromDate() {
      return this.novaCore.getFormattedTime(
        this.originalEvent.start,
        this.novaCore.DateTimeFormats.ShortDayShortMonthFullYear
      );
    },
    fromStart() {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        this.originalEvent.start,
        this.eventWarehouse.timezone,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    },
    fromEnd() {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        this.originalEvent.end,
        this.eventWarehouse.timezone,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    },
    toDate() {
      return this.novaCore.getFormattedTime(
        this.event.end,
        this.novaCore.DateTimeFormats.ShortDayShortMonthFullYear
      );
    },
    toStart() {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        this.event.start,
        this.eventWarehouse.timezone,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    },
    toEnd() {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        this.event.end,
        this.eventWarehouse.timezone,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    },
    fromDock() {
      return this.originalEventDock.name;
    },
    toDock() {
      return this.eventDock.name;
    }
  },
  data() {
    return {
      newLoadType: null,
      loading: false
    };
  },
  methods: {
    async submitLoadType() {
      if (!this.newLoadType) {
        return;
      }
      if (this.returnLoadType) {
        this.$emit('update-selected-load-type', this.newLoadType);
        this.$emit('close');
        return;
      }
      this.loading = true;

      const params = {
        loadTypeId: this.newLoadType.id,
        dockId: this.eventDock.id,
        start: DateTime.fromISO(
          this.event.startStr,
          this.event.extendedProps.dock.warehouse.timezone
        )
          .toUTC()
          .toISO()
      };

      try {
        const response = await axios.patch(`appointment/${this.event.id}`, params);

        if (response?.data?.data) {
          await this.$store.dispatch('Appointments/triggerSocketEvent', {
            response,
            appointment: this.event
          });
        }
      } finally {
        this.newLoadType = null;
        this.loading = false;
        this.$emit('close');
      }
    }
  }
};
</script>
