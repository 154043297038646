<template>
  <div class="full-height d-flex flex-column justify-center align-center">
    <template v-if="shouldShowMessage">
      <img class="welcome-image" alt="welcome to Opendock Nova" :src="welcomeImg" />
      <h3 class="mt-4 mb-2">{{ greeting }}</h3>
      <p class="text-center font-size-small mb-8">
        In order to fully use Opendock, you first need to
        <strong>create your Organization</strong>
        .
      </p>

      <create-org-dialog></create-org-dialog>
    </template>
    <template v-else>
      <mobile-opendock-logo />
    </template>
  </div>
</template>

<script>
import welcomeImg from '@/images/welcome.png';

/**
 * Card displayed when no org has been created yet
 * @displayName Org Required Card
 */
export default {
  name: 'new-org-welcome',
  computed: {
    greeting() {
      return this.shouldShowMessage ? `Hello, ${this.$me.firstName} ${this.$me.lastName}` : '';
    },
    shouldShowMessage() {
      return Boolean(this.$me?.id);
    },
    welcomeImg() {
      return welcomeImg;
    }
  }
};
</script>
