<template>
  <div class="flex-grow-1">
    <template v-if="readOnly">
      <div class="mt-3 font-size-x-small">Email CCs</div>
      <div class="d-flex flex-wrap gap-2 mt-3">
        <v-chip :color="'dark-gray'" v-for="(email, index) in modelValue" :key="`email-${email}`">
          {{ email }}
        </v-chip>
      </div>
    </template>

    <v-combobox
      v-else
      multiple
      v-model="emails"
      @update:model-value="validate"
      @blur="validate"
      :label="label"
      :delimiters="[',', ' ']"
      v-bind="{ ...$attrs, ...$props }"
      :error-messages="errorMessage"
      placeholder="Email Subscribers"
      persistent-placeholder
      :class="{ 'label-with-tooltip': label, 'stack-selections': stacked }"
      hide-details="auto"
      :hide-no-data="hideNoData"
      :prepend-icon="icon"
      variant="outlined"
      return-object
      no-filter
      clear-on-select
      :menu-props="{ openOnFocus: true, openOnClick: false }"
      chips>
      <template #chip="{ item, index }">
        <v-chip
          :key="item.value"
          class="email-chip"
          :color="invalidEmails.includes(item.value) ? 'error' : chipColor"
          closable
          @click.stop="openEdit(item)"
          @click:close.stop="removeChip(item.value)"
          label>
          {{ item.value }}
        </v-chip>
      </template>

      <template #label v-if="label">
        <div class="d-flex align-center">
          {{ label }}
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-icon v-bind="props" class="ml-1 text-grey-darken-2" size="small">
                mdi-help-circle
              </v-icon>
            </template>
            {{ tooltipText }}
          </v-tooltip>
        </div>
      </template>
      <template v-if="!hideNoData" #no-data>
        <v-list-item class="input-instructions">
          <v-list-item-title>
            <span v-html="instructions"></span>
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-combobox>

    <v-dialog v-model="editingItemDialog" max-width="320">
      <v-card>
        <v-card-title>Update Email</v-card-title>

        <v-card-text>
          <v-text-field v-model="editingValue" label="Edit" single-line autofocus></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" variant="text" @click="closeEdit">Nevermind</v-btn>
          <v-btn
            color="primary"
            variant="text"
            :disabled="!editingValue || editingValue === editingItem.value"
            @click="handleEmailSave">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

/**
 * @displayName Email List Field
 */
export default {
  props: {
    /**
     * @model
     */
    modelValue: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Field label
     */
    label: {
      type: String,
      required: false
    },
    /**
     * List item color
     */
    chipColor: {
      type: String,
      required: false,
      default: 'secondary'
    },
    /**
     * Allow deletion of individual list items
     */
    allowChipDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * tooltipText
     */
    tooltipText: {
      type: String,
      required: false,
      default: 'List of email addresses that will receive all notifications about the appointment.'
    },
    /**
     * Field instructions
     */
    instructions: {
      type: String,
      required: false,
      default: 'Press <kbd>enter</kbd> or <kbd>tab</kbd> to add an email'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Stack up items
     */
    stacked: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Icon to prepend
     */
    icon: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      invalidEmails: [],
      search: null,
      updateEmailValue: null,
      emails: [],
      errorMessage: null,
      unsavedValue: null,
      hideNoData: false,
      confirmEdit: null,
      editingItem: null,
      editingValue: ''
    };
  },
  methods: {
    validate() {
      this.invalidEmails = [];

      // Ensure that "unsaved" text is applied when a form is submitted.
      if (
        this.unsavedValue &&
        this.unsavedValue !== '' &&
        !this.emails.includes(this.unsavedValue)
      ) {
        this.emails.push(this.unsavedValue);
      }

      this.emails?.forEach(email => {
        if (!this.isValidEmail(email)) {
          this.invalidEmails.push(email);
        }
      });

      const invalidLen = this.invalidEmails.length;
      this.errorMessage = invalidLen
        ? `There ${invalidLen === 1 ? 'is' : 'are'} ${invalidLen} invalid email${
            invalidLen === 1 ? '' : 's'
          }.`
        : null;

      if (!invalidLen) {
        this.$emit('update:model-value', this.emails);
      }

      return !invalidLen;
    },
    // TODO we have created a task to move this to a generic location
    /**
     * Validate email
     * @public
     * @param {string} email
     */
    isValidEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    },
    openEdit(item) {
      this.editingItem = item;
      this.editingValue = item.value;
    },
    closeEdit() {
      this.editingItem = null;
      this.editingValue = '';
    },
    /**
     * Save email
     * @public
     * @param item
     */
    handleEmailSave() {
      if (!this.editingItem) {
        return;
      }
      this.emails = this.emails?.map(email =>
        email === this.editingItem.value ? this.editingValue : email
      );
      this.updateEmailValue = null;
      this.validate();
      this.closeEdit();
    },
    setEmails() {
      const emailSet = new Set();
      if (this.modelValue?.length > 0) {
        this.modelValue.forEach(email => {
          emailSet.add(email);
        });
      }

      this.emails = [...emailSet];
    },
    removeChip(value) {
      const index = this.emails.indexOf(value);
      if (index > -1) {
        this.$emit(
          'update:model-value',
          this.emails.filter(email => email !== value)
        );
      }
    }
  },
  mounted() {
    this.setEmails();
  },
  computed: {
    editingItemDialog() {
      return Boolean(this.editingItem);
    }
  },
  watch: {
    modelValue(val) {
      this.setEmails();
      this.hideNoData = !isEmpty(val);
    }
  }
};
</script>

<style scoped lang="scss">
.email-chip {
  margin-bottom: 0 !important;
}
</style>
