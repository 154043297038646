/**
 * Mixin used in selects
 * @mixin
 * @displayName Select Mixin
 */
export default {
  props: {
    /**
     * Hide icon
     */
    hideIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Select label
     */
    label: {
      type: String,
      required: false
    },
    /**
     * Is Select Required
     */
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    truncationOptions: {
      type: Object,
      required: false,
      default() {
        return {
          isTruncated: false,
          truncateLength: 15
        };
      }
    },
    maximumSelections: {
      type: Number,
      required: false,
      default: null
    },
    transformIdsToObjects: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      defaultTruncateLength: 15
    };
  },
  computed: {
    isAtSelectionLimit() {
      return this.maximumSelections && this.modelValue.length >= this.maximumSelections;
    }
  },
  methods: {},
  watch: {
    modelValue(newVal) {
      if (newVal?.length && this.selectableItems?.length && this.transformIdsToObjects) {
        if (typeof newVal[0] === 'string') {
          this.$emit(
            'update:model-value',
            this.selectableItems.filter(item => newVal.includes(item.id))
          );
        }
      }
    }
  }
};
