<template>
  <v-dialog
    v-model="isOpen"
    :width="width"
    @keydown.escape="closeDialog"
    v-bind="{ ...$props, ...$attrs }">
    <v-card data-testid="carrier-contact-remove-dialog">
      <v-card-title class="d-flex flex-row align-center">
        <span class="text-h5">Remove contact from list?</span>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon="mdi-close" @click="closeDialog" />
      </v-card-title>
      <v-card-text class="dialog-body">
        <carrier-contact-card
          :carrier="carrier"
          :company="company"
          :is-favorite="carrierContact.isFavorite" />
        <div class="confirmation-wrapper" v-if="subscriberCount > 0 || carrierContact.isFavorite">
          <span>
            Removing
            <span class="font-weight-bold">{{ carrier?.fullName || '' }} - {{ company.name }}</span>
            fom your Org's list will also
            <span v-if="!carrierContact.isFavorite">
              remove
              <span class="font-weight-bold">{{ carrierContact.emailCCs.length }}</span>
              email {{ novaCore.pluralize(subscriberCount, 'subscriber') }} associated with it:
            </span>
            <span v-else-if="subscriberCount < 1">unfavorite the contact.</span>
            <span v-else>
              unfavorite the contact and remove
              <span class="font-weight-bold">{{ carrierContact.emailCCs.length }}</span>
              email {{ novaCore.pluralize(subscriberCount, 'subscriber') }} associated with it:
            </span>
          </span>
          <div class="email-subscribers" v-if="subscriberCount > 0">
            <v-chip v-for="email in carrierContact.emailCCs" :key="email">
              {{ email }}
            </v-chip>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions">
        <v-btn
          variant="text"
          @click="closeDialog"
          data-testid="carrier-contact-remove-dialog-action-nevermind">
          Nevermind
        </v-btn>
        <v-btn
          variant="text"
          color="error"
          @click="removeCarrierContact"
          data-testid="carrier-contact-remove-dialog-action-remove">
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { isEmpty } from 'lodash';
import { useEventBasedDialog } from '@/composables/useEventBasedDialog';
import * as novaCore from '@nova/core';
import useCarrierContacts from '@/composables/useCarrierContacts';

export default defineComponent({
  name: 'EventTriggeredRemoveCarrierContactDialog',
  props: {
    width: { type: Number, default: 550 }
  },
  setup() {
    const carrierContacts = useCarrierContacts();

    const { isOpen, dialogProps, closeDialog } = useEventBasedDialog({
      eventName: 'open-remove-carrier-contact-dialog'
    });

    const carrierContact = computed(() => dialogProps.value?.carrierContact);
    const mixpanelData = computed(() => dialogProps.value?.mixpanelData);
    const onContactRemoved = computed(() => dialogProps.value?.onContactRemoved);

    const removeCarrierContact = async () => {
      if (!dialogProps.value) {
        console.error('Failed to remove carrier contact: No dialog props found');
        return;
      }

      try {
        await carrierContacts.remove(
          { id: carrierContact.value.id },
          { mixpanelData: mixpanelData.value }
        );

        onContactRemoved.value?.();
        closeDialog();
      } catch (error) {
        console.error('Failed to remove carrier contact', error);
      }
    };

    const subscriberCount = computed(() => carrierContact.value.emailCCs?.length || 0);

    const company = computed(() => {
      const contact = carrierContact.value;

      return !isEmpty(contact?.company) ? contact.company : contact?.user.company;
    });

    const carrier = computed(() => {
      const user = carrierContact.value.user;
      if (!user) return null;

      return {
        ...user,
        fullName: user.fullName || novaCore.namesToFullName(user.firstName, user.lastName)
      };
    });

    return {
      carrier,
      carrierContact,
      company,
      isOpen,
      mixpanelData,
      subscriberCount,

      closeDialog,
      removeCarrierContact
    };
  }
});
</script>

<style scoped lang="scss">
.dialog-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirmation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
  margin-block-start: 32px;
}

.email-subscribers {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}
</style>
