<template>
  <div class="mb-4 full-width d-flex flex-row align-center">
    <h3 v-if="label" :class="disabled ? 'text--disabled' : ''" class="mr-4">
      {{ label }}:
      <help-icon-tooltip v-if="tooltipText">
        {{ tooltipText }}
      </help-icon-tooltip>
    </h3>
    <v-autocomplete
      :required="required"
      :rules="rules"
      :prepend-icon="!hideIcon ? 'mdi-truck' : ''"
      class="pt-0"
      hide-details="auto"
      @update:model-value="updateValue"
      :model-value="modelValue"
      @update:search="onSearchInput"
      :items="groupedCarriers"
      item-value="id"
      no-filter
      v-bind="$attrs"
      ref="carrierSelect"
      :label="modelValue ? null : placeholder"
      :loading="isLoading"
      return-object
      :multiple="multiple"
      :disabled="disabled">
      <template v-if="showWarning" v-slot:prepend-item>
        <v-alert color="chalkorange" class="mx-1 mb-0">
          <p class="mb-1">
            <v-icon size="small" color="primary" class="mr-2">mdi-information</v-icon>
            <strong>Appointment notifications will be sent out to your carrier if enabled.</strong>
          </p>

          <p class="mt-1 mb-0 ml-7">
            To schedule a test appointment, please select an email ending in “@example.com”.
          </p>
        </v-alert>
      </template>
      <template v-slot:selection="{ item }">
        <span v-if="item.raw.id">
          {{ getSelectedCarrierText(item.raw, truncateLength, truncateAsWhole) }}
        </span>
      </template>
      <template v-slot:item="{ item, props }">
        <v-list-item
          density="default"
          class="py-2"
          :active="isCarrierFavorite(item.raw)"
          v-if="!item.raw.header && !item.raw.divider"
          v-bind="props"
          :title="null">
          <div class="item-wrapper">
            <div class="name-and-info">
              <span :class="{ 'carrier-name': isCarrierFavorite(item.raw) }">
                {{ getCarrierName(item.raw) }}
              </span>
              <span class="company-info">
                <v-icon size="14px" class="mr-1">mdi-truck-outline</v-icon>
                <template v-if="item.raw.company?.id">
                  <strong>{{ item.raw.company.name }}</strong>
                  {{ item.raw.company.scac }}
                </template>
              </span>
            </div>
            <div class="email-and-subscribers">
              <span class="carrier-email">
                {{ item.raw.email }}
              </span>
              <span class="subscribers" v-if="getCarrierEmailCCs(item.raw).length > 0">
                <v-icon size="14px">mdi-email-fast-outline</v-icon>
                +{{ getCarrierEmailCCs(item.raw).length }}
                subscribers
              </span>
            </div>
            <div class="favorite">
              <v-icon color="primary" v-if="isCarrierFavorite(item.raw)">mdi-star</v-icon>
            </div>
          </div>
        </v-list-item>
        <v-divider v-else-if="item.raw.divider"></v-divider>
        <v-list-subheader class="v-subheader py-0" v-else-if="item.raw.header">
          {{ item.raw.header }}
        </v-list-subheader>
      </template>

      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            {{
              filters.searchStr && !carriers.length
                ? 'No matching carrier contacts found...'
                : placeholder
            }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:append-item v-if="!isLoading && showCarrierCreateButton">
        <v-list-item @click="openCreateCarrierContactDialog">
          <v-list-item-title>
            <span class="typography-body-md mr-1">Didn't find your carrier contact?</span>
            <span class="typography-body-md-bold">Create new</span>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template #label v-if="required">
        {{ placeholder }}
        <span class="text-red"><strong>*</strong></span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import carrierSelectMixin from '@/components/mixins/carrierSelectMixin';
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import selectMixin from '@satellite/components/mixins/selectMixin';
import { emitCustomEvent } from '@/composables/useCustomEvent';

export default {
  name: 'carrier-select',
  mixins: [selectMixin, dataListMixin, carrierSelectMixin],
  props: {
    modelValue: {
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showWarning: {
      type: Boolean,
      required: false,
      default: false
    },
    showCarrierCreateButton: {
      type: Boolean,
      required: false,
      default: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    hideIcon: {
      type: Boolean,
      required: false
    },
    warehouse: {
      type: Object,
      required: false
    },
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    },
    truncateLength: {
      type: Number,
      required: false,
      default: 40
    },
    truncateAsWhole: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      carriers: [],
      filters: {
        searchStr: null
      },
      searchFields: ['firstName', 'lastName', 'email', 'company.name'],
      isLoading: false,
      sortBy: ['firstName'],
      placeholder: 'Start typing to find a carrier contact'
    };
  },
  methods: {
    onSearchInput(inputVal) {
      this.filters.searchStr = inputVal;
    },
    async updateValue(carrier) {
      await this.$emit('update:model-value', carrier);
    },
    handleContactCreated(contact) {
      this.carriers.push(contact);
      this.updateValue(contact);
      this.$refs.carrierSelect.isMenuActive = false;
    },
    openCreateCarrierContactDialog() {
      emitCustomEvent('open-create-carrier-contact-dialog', {
        mixpanelEntryPoint: 'Carrier Contacts Select List',
        onContactCreated: this.handleContactCreated
      });
    }
  },
  computed: {
    groupedCarriers() {
      const whFavorites = [];
      const orgFavorites = [];
      const notFavorites = [];
      this.carriers.forEach(carrier => {
        if (!carrier.orgCarrierSettings?.[0]) {
          notFavorites.push(carrier);
        } else if (carrier.orgCarrierSettings[0].favoriteWarehouseIds === null) {
          orgFavorites.push(carrier);
        } else if (
          this.warehouse?.id &&
          carrier.orgCarrierSettings[0].favoriteWarehouseIds.includes(this.warehouse.id)
        ) {
          whFavorites.push(carrier);
        } else {
          notFavorites.push(carrier);
        }
      });

      const grouped = [];
      if (whFavorites.length > 0) {
        grouped.push({ header: `${this.warehouse.name} favorites` });
        grouped.push(...whFavorites);
        grouped.push({ divider: true });
      }
      if (orgFavorites.length > 0) {
        grouped.push({ header: `${this.$org.name} favorites` });
        grouped.push(...orgFavorites);
        grouped.push({ divider: true });
      }
      if (notFavorites.length > 0) {
        grouped.push({ header: 'other' });
        grouped.push(...notFavorites);
        grouped.push({ divider: true });
      }

      return grouped;
    }
  },
  watch: {
    warehouse(newVal, oldVal) {
      // When clearing the WH select, the change event is triggered twice...
      if (newVal?.id || oldVal?.id) {
        this.getData();
      }
    }
  },
  async mounted() {
    if (this.modelValue?.id) {
      this.carriers.push(this.modelValue);
    } else {
      await this.getData();
    }
  }
};
</script>

<style scoped lang="scss">
.item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name-and-info {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.email-and-subscribers {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.favorite {
  display: flex;
  justify-content: center;
}

.carrier-name {
  color: $color-primary-60;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.carrier-email {
  color: $color-neutral-90;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.company-info {
  color: #585f66;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.06px;
}

.subscribers {
  color: $color-neutral-90;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.06px;
}

.v-subheader {
  color: #838d96;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.06px;
  text-transform: uppercase;
  height: inherit;
  padding-bottom: 8px;
  padding-top: 12px;
}
</style>
