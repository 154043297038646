<template>
  <v-combobox
    ref="combobox"
    :class="{ required: field.required }"
    :model-value="field.value"
    :rules="validationRules"
    :items="field.dropDownValues"
    v-bind="{ ...$props, ...$attrs }"
    @update:model-value="value => $emit('input', value || '')"
    density="compact"
    clearable
    open-on-clear>
    <template #label>Choose or Type a value</template>
  </v-combobox>
</template>

<script>
import customFieldTypeMixinOld from '@/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  name: 'custom-field-combobox-old',
  mixins: [customFieldTypeMixinOld]
};
</script>
