<template>
  <dialog-base
    v-bind="{ ...$props, ...$attrs }"
    :key="renderKey"
    header="Clone Schedule"
    :scrollable="true"
    max-width="450px"
    width="450px"
    has-dialog-actions
    loading-message="Cloning Schedule..."
    @close="close"
    @keydown.enter="emitCopySchedule"
    @submit="emitCopySchedule">
    <template v-slot:activator="props" v-if="!externalActivator">
      <slot v-if="$slots.activator" name="activator" v-bind="props"></slot>
      <v-btn
        v-else
        density="compact"
        v-bind="props"
        variant="plain"
        block
        size="small"
        prepend-icon="mdi-content-copy"
        class="bg-white">
        Another Dock...
      </v-btn>
    </template>
    <template v-slot:body>
      <form-base hide-required-legend>
        <template #form>
          <v-form ref="form">
            <h4 class="text-black">Dock schedule to clone from:</h4>
            <dock-select
              data-testid="clone-dock-select"
              :multi-select="false"
              :display-checkboxes="false"
              :clearable="false"
              :prepend-icon="null"
              v-model="selectedDock"
              :docks="docks"
              placeholder="Select Dock"></dock-select>
          </v-form>
        </template>
      </form-base>
    </template>
    <template #dialog-actions>
      <action-group
        @cancel="close"
        confirm-icon="content-copy"
        confirm-label="Clone Schedule"
        @confirm="emitCopySchedule"></action-group>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit create/edit dock form
 * @displayName Edit Dock Dialog
 */
export default {
  name: 'CloneDockScheduleDialog',
  mixins: [dialogMixin],
  props: {
    docks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      selectedDock: {}
    };
  },
  methods: {
    emitCopySchedule() {
      this.$emit('copy-schedule', this.selectedDock.schedule);
      this.selectedDock = {};
      this.close();
    }
  }
};
</script>
