<template>
  <table class="internal-border" :class="`table-${$.uid}`" data-testid="clock-selector-table">
    <thead>
      <tr class="font-size-x-small">
        <th></th>
        <th
          v-for="hour in hourLabels"
          :key="hour.label"
          @click="handleHourClick(hour.value)"
          class="clickable">
          {{ hour.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="" v-for="minute in orderedMinuteKeys" :key="minute">
        <td
          class="minute-label clickable font-size-x-small"
          @click="() => handleMinuteClick(minute)">
          {{ minute }}
        </td>
        <td v-for="clock in clocksKeyedByMinute[minute]" :key="clock.value">
          <v-checkbox
            color="primary"
            :ref="`${minute}-row-checkbox`"
            density="compact"
            class="mt-0 font-size-small"
            hide-details
            :data-value="clock.value"
            v-model="checkedClocks"
            :value="clock.value"
            @update:model-value="updateVal"></v-checkbox>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/**
 * Clock Selector
 * @displayName Clock Selector
 */
import { DateTime } from 'luxon';
import renderMixin from '@satellite/components/mixins/renderMixin';

export default {
  name: 'ClockSelector',
  mixins: [renderMixin],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    /**
     * Array of objects containing items split into specified interval length for use in date range select items
     * [{text: "12:00 am", value: "0:00"}, {text: "12:30 am, value: "0:30"}...]
     * @returns {*[]}
     */
    dateRangeSelectItems() {
      return this.novaCore.makeFullDayClockOptions(this.intervalLength);
    },
    minuteValues() {
      const iterations = 60 / this.intervalLength;
      const hourDateTime = DateTime.now().set({ hour: 1, minute: 0, second: 0 });
      const minutes = [];
      for (let i = 0; i < iterations; i++) {
        const newMinute = hourDateTime.plus({
          minute: hourDateTime.minute + this.intervalLength * i
        }).minute;
        minutes.push(this.novaCore.leftPadString(newMinute, 2, '0', 2));
      }
      return minutes;
    },
    hourLabels() {
      const initialDateTime = DateTime.now().set({ hour: 0, minute: 0, second: 0 });
      const hourLabels = [];
      for (let i = 0; i < 24; i++) {
        hourLabels.push({
          label: this.novaCore
            .formatDateTimeWithMilitarySupport(
              initialDateTime.plus({ hour: i }),
              null,
              this.novaCore.LuxonDateTimeFormats.Hour12,
              this.$isMilitaryTimeEnabled(this.$org),
              this.novaCore.LuxonDateTimeFormats.Extended24HrTimeNoPadding
            )
            .replace(' ', '')
            .toLowerCase(),
          value: this.novaCore.formatDateTimeWithMilitarySupport(
            initialDateTime.plus({ hour: i }),
            null,
            this.novaCore.LuxonDateTimeFormats.Extended24HrTimeNoPadding,
            this.$isMilitaryTimeEnabled(this.$org),
            this.novaCore.LuxonDateTimeFormats.Extended24HrTimeNoPadding
          )
        });
      }
      return hourLabels;
    },
    clockItems() {
      return [...this.novaCore.makeFullDayClockOptions(this.intervalLength)];
    },
    orderedMinuteKeys() {
      let i = 0;
      const minuteKeys = [];
      while (i < 60) {
        minuteKeys.push(this.novaCore.leftPadString(i, 2, '0', 2));
        i += this.intervalLength;
      }
      return minuteKeys;
    },
    clocksKeyedByMinute() {
      let availableClocks = this.clockItems;
      const clocksKeyedByMinute = {};
      availableClocks.forEach(clock => {
        const clockParts = clock.text.split(':');
        const minute = clockParts[1].split(' ')[0];
        if (!this.novaCore.objPropExists(clocksKeyedByMinute, minute)) {
          clocksKeyedByMinute[minute] = [];
        }
        if (clock.value !== '23:59') {
          clocksKeyedByMinute[minute].push(clock);
        }
      });
      return clocksKeyedByMinute;
    }
  },
  data() {
    return {
      loading: false,
      intervalLength: 5, // This will become a setting at some point - easy to swap out here
      isScheduleInitialized: false,
      checkedClocks: []
    };
  },
  methods: {
    updateVal(newSelectedClocks) {
      this.$emit('update:model-value', newSelectedClocks);
    },
    handleHourClick(time) {
      const valsToUpdate = [];
      this.minuteValues.forEach(minute => {
        const timeParts = time.split(':');
        valsToUpdate.push(`${timeParts[0]}:${minute}`);
      });
      const allChecked = valsToUpdate.every(val => {
        return this.checkedClocks.includes(val);
      });
      this.createNewTimes(allChecked, valsToUpdate);
    },
    createNewTimes(allChecked, valsToUpdate) {
      if (!allChecked) {
        const newTimes = Array.from(new Set([...this.checkedClocks, ...valsToUpdate]));
        this.updateVal(newTimes);
      } else {
        const newTimes = [...this.checkedClocks];
        valsToUpdate.forEach(val => {
          const index = newTimes.indexOf(val);
          newTimes.splice(index, 1);
        });
        this.updateVal(newTimes);
      }
    },
    handleMinuteClick(minute) {
      const els = this.$refs[`${minute}-row-checkbox`];
      const valsToUpdate = els.map(el => el.$el.querySelector('input').value);
      const allChecked = valsToUpdate.every(val => {
        return this.checkedClocks.includes(val);
      });
      this.createNewTimes(allChecked, valsToUpdate);
    }
  },
  mounted() {
    this.checkedClocks = [...this.modelValue];
  },
  watch: {
    modelValue(newVal) {
      // Keep local array in sync whenever parent changes
      this.checkedClocks = [...newVal];
    }
  }
};
</script>
