<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :header="header"
    :scrollable="false"
    has-dialog-actions
    loading-message="Creating Load Type..."
    @close="close"
    @confirm="$refs.createLoadTypeForm.submit()"
    max-width="900px"
    width="900px">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <v-tooltip location="top" :disabled="!isCreationButtonDisabled">
        <template v-slot:activator="{ props }">
          <slot v-if="$slots.activator" name="activator" v-bind="slotProps"></slot>
          <div v-else v-bind="props">
            <primary-button
              :disabled="isCreationButtonDisabled"
              :before-icon="beforeIcon"
              v-on="slotProps.on"
              v-bind="slotProps.attrs"
              large
              class="mr-2">
              Create Load Type
            </primary-button>
          </div>
        </template>
        <span>{{ getDisabledCreationMessage }}</span>
      </v-tooltip>
    </template>
    <template v-slot:body>
      <load-type-form
        @close="close"
        @saved="data => $emit('saved', data)"
        :omit-advanced-settings="true"
        :skip-dirty-validation="true"
        :compact="true"
        ref="createLoadTypeForm"
        :warehouse="warehouse"></load-type-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import createLoadTypeMixin from '@/modules/load_type/mixins/createLoadTypeMixin';

export default {
  mixins: [dialogMixin, createLoadTypeMixin],
  props: {
    beforeIcon: {
      type: String,
      required: false,
      default: 'plus'
    },
    warehouse: {
      type: Object,
      required: false
    },
    postSubmissionRouteName: {
      type: String,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    header() {
      if (this.warehouse) {
        return 'Create Warehouse Load Type';
      } else {
        return 'Create Org Load Type';
      }
    }
  },
  methods: {
    async close() {
      this.$emit('close');
      if (this.postSubmissionRouteName) {
        await this.$router.push({
          name: this.postSubmissionRouteName,
          params: { warehouseId: this.warehouse?.id }
        });
      }
      this.renderKey++;
    }
  }
};
</script>
