<template>
  <div class="v-row d-flex flex-column gap-4">
    <div
      v-for="localField of localFields"
      :key="localField.formFieldId"
      class="d-flex flex-row gap-2">
      <h3 v-if="localField.customField.label">
        {{ localField.customField.label }}:
        <help-icon-tooltip v-if="localField.customField.description">
          {{ localField.customField.description }}
        </help-icon-tooltip>
      </h3>

      <component
        v-bind="$attrs"
        v-bind:is="customFieldComponents[localField.customField.type]"
        :field="localField.customField"
        v-model="localField.value"
        single-line
        :warehouse="warehouse" />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import { CustomFormDataEntitiesEnum } from '@nova/core';
import { useCustomForms } from '@/composables';

import CustomFieldAction from '@/modules/custom_fields/components/field_types/CustomFieldAction.vue';
import CustomFieldBigstring from '@/modules/custom_fields/components/field_types/CustomFieldBigstring.vue';
import CustomFieldBool from '@/modules/custom_fields/components/field_types/CustomFieldBool.vue';
import CustomFieldCombobox from '@/modules/custom_fields/components/field_types/CustomFieldCombobox.vue';
import CustomFieldDate from '@/modules/custom_fields/components/field_types/CustomFieldDate.vue';
import CustomFieldDocument from '@/modules/custom_fields/components/field_types/CustomFieldDocument.vue';
import CustomFieldDropdownmultiselect from '@/modules/custom_fields/components/field_types/CustomFieldDropdownmultiselect.vue';
import CustomFieldDropdown from '@/modules/custom_fields/components/field_types/CustomFieldDropdown.vue';
import CustomFieldEmail from '@/modules/custom_fields/components/field_types/CustomFieldEmail.vue';
import CustomFieldMultidocument from '@/modules/custom_fields/components/field_types/CustomFieldMultidocument.vue';
import CustomFieldNumber from '@/modules/custom_fields/components/field_types/CustomFieldNumber.vue';
import CustomFieldPhone from '@/modules/custom_fields/components/field_types/CustomFieldPhone.vue';
import CustomFieldString from '@/modules/custom_fields/components/field_types/CustomFieldString.vue';
import CustomFieldTimestamp from '@/modules/custom_fields/components/field_types/CustomFieldTimestamp.vue';

export default defineComponent({
  name: 'CreateAppointmentCustomForms',
  emits: ['update:value'],
  props: {
    triggers: {
      type: Array,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    }
  },
  components: {
    CustomFieldAction,
    CustomFieldBigstring,
    CustomFieldBool,
    CustomFieldCombobox,
    CustomFieldDate,
    CustomFieldDocument,
    CustomFieldDropdownmultiselect,
    CustomFieldDropdown,
    CustomFieldEmail,
    CustomFieldMultidocument,
    CustomFieldNumber,
    CustomFieldPhone,
    CustomFieldString,
    CustomFieldTimestamp
  },
  setup(props, { emit }) {
    const { getCustomField, customFieldComponents } = useCustomForms();
    const localFields = ref([]);
    const propagateValueChange = () =>
      emit(
        'update:value',
        localFields.value.map(field => ({
          formFieldId: field.formFieldId,
          triggerId: field.triggerId,
          value: field.value
        }))
      );

    const setLocalFields = () => {
      for (const trigger of props.triggers.filter(trigger => Boolean(trigger.flow?.formFrom))) {
        for (const formField of trigger.flow.formFrom.formFields.sort(
          (a, b) => a.order - b.order
        )) {
          localFields.value.push({
            form: trigger.flow.formFrom,
            customField: getCustomField(formField),
            entityName: CustomFormDataEntitiesEnum.appointment,
            formFieldId: formField.id,
            triggerId: trigger.id,
            value: formField.field.type === 'bool' ? false : null
          });
        }
      }
    };

    // Build the initial value for the request
    onMounted(() => {
      setLocalFields();
      propagateValueChange();
    });

    watch(localFields, () => propagateValueChange(), { deep: true });

    return {
      propagateValueChange,
      localFields,
      customFieldComponents
    };
  }
});
</script>
