<template>
  <header id="mobile-header-main" class="mobile-device">
    <v-app-bar-nav-icon
      v-if="$me"
      @click="showNavDialog = true"
      class="text-white"
      @click.stop=""></v-app-bar-nav-icon>
    <nav-dialog-mobile
      fullscreen
      :card-classes="['mobile-blue-bg']"
      transition="dialog-bottom-transition"
      external-activator
      @close="showNavDialog = false"
      :show-dialog="showNavDialog"></nav-dialog-mobile>

    <template
      v-if="
        $route.meta.title === 'Appointments' && $route.meta.title === $mobileAttributes.pageTitle
      ">
      <v-btn
        variant="text"
        class="btn-plain"
        @click="showWarehouseDialog = true"
        data-testid="warehouse-select-trigger-header">
        <v-icon size="small" class="mr-1 text-white">mdi-map-marker</v-icon>
        <span>{{ selectedWarehouseName }}</span>
        <v-icon size="small" class="mt-1 text-white">mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <strong class="text-white" v-else data-testid="mobile-page-title">
      {{ $mobileAttributes.pageTitle }}
    </strong>

    <mobile-opendock-icon />

    <warehouse-select-dialog-mobile
      v-if="$appLoaded"
      @close="showWarehouseDialog = false"
      :card-classes="['mobile-blue-bg']"
      external-activator
      :show-dialog="showWarehouseDialog"></warehouse-select-dialog-mobile>
  </header>
</template>

<script>
export default {
  computed: {
    selectedWarehouseName() {
      return this.$selectedWarehouse?.name ?? '';
    }
  },
  data() {
    return {
      drawerState: false,
      selectedWarehouse: null,
      mounted: false,
      showWarehouseDialog: false,
      showNavDialog: false
    };
  },
  methods: {
    setMobileStyles() {
      // Polls the application container element until it's rendered on the screen
      this.waitForElementRender(
        'querySelector',
        '.v-main__wrap > .container',
        this,
        (containerEl, vueContext) => {
          let headerHeight;
          this.waitForElementRender(
            'getElementById',
            'mobile-header-main',
            vueContext,
            headerEl => {
              headerHeight = headerEl.getBoundingClientRect().height;
              containerEl.style.cssText = `height: ${
                window.innerHeight - headerHeight
              }px;top: ${headerHeight}px`;
            }
          );
        }
      );
    },
    handleOrientationChange() {
      const afterOrientationChange = () => {
        this.setMobileStyles();
        window.removeEventListener('resize', this.setMobileStyles);
      };
      window.addEventListener('resize', afterOrientationChange);
    }
  },
  mounted() {
    window.addEventListener('orientationchange', this.handleOrientationChange, false);
    this.setMobileStyles();
  },
  beforeUnmount() {},
  watch: {
    async selectedWarehouse(newWarehouse) {
      await this.$store.dispatch('Calendar/setSelectedWarehouse', newWarehouse);
    },
    $selectedWarehouse() {
      if (!this.mounted) {
        this.selectedWarehouse = this.$selectedWarehouse;
      }
      this.mounted = true;
    }
  }
};
</script>

<style scoped lang="scss">
#mobile-header-main {
  background-color: getVuetifyColor(mobileblue);
  max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0.5rem;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;

  #logo-icon {
    margin: 0 6px;
  }
}

#warehouse-select-dialog-mobile {
  background-color: getVuetifyColor(mobileblue) !important;
}
</style>
