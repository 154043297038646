<template>
  <v-tooltip location="bottom" :max-width="maxWidth">
    <template v-slot:activator="{ props }">
      <v-icon
        :color="iconStyleClass"
        v-bind="props"
        size="x-small"
        class="field-label-tooltip"
        :class="{ 'd-block': dense, [zIndexClass]: true }">
        {{ iconName }}
      </v-icon>
    </template>
    <span><slot></slot></span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    iconStyleClass: {
      type: String,
      required: false,
      default: 'primary'
    },
    iconName: {
      type: String,
      required: false,
      default: 'mdi-help-circle'
    },
    dense: {
      type: Boolean,
      required: false
    },
    maxWidth: {
      type: Number,
      default: 300
    },
    zIndexClass: {
      type: String,
      required: false,
      default: 'z-2'
    }
  }
};
</script>
