import { mapState } from 'vuex';

/**
 * Store states map
 * @mixin
 * @displayName Globally Mapped States
 */
export default {
  computed: {
    ...mapState('navigation', {
      $pageTitle: state => state.pageTitle,
      $drawer: state => state.drawer
    }),
    ...mapState('Auth', {
      $me: state => state.me,
      $appLoaded: state => state.appLoaded,
      $rolePermissions: state => state.rolePermissions,
      $carrierViewAllowedUserRoles: state => state.carrierViewAllowedUserRoles
    }),
    ...mapState('Warehouses', {
      $warehouses: state => state.warehouses,
      $warehousePagination: state => state.pagination
    }),
    ...mapState('WarehouseGroups', {
      $warehouseGroups: state => state.warehouseGroups,
      $warehouseGroupsPagination: state => state.pagination
    }),
    ...mapState('Orgs', {
      $org: state => state.org,
      $orgs: state => state.orgs,
      $orgPagination: state => state.pagination
    }),
    ...mapState('Users', {
      $users: state => state.users,
      $userPagination: state => state.pagination
    }),
    ...mapState('Appointments', {
      $appointments: state => state.appointments
    }),
    ...mapState('Companies', {
      $companies: state => state.companies
    }),
    ...mapState('Docks', {
      $docks: state => state.docks,
      $dockPagination: state => state.pagination
    }),
    ...mapState('Calendar', {
      $selectedWarehouse: state => state.selectedWarehouse,
      $selectedDocks: state => state.selectedDocks,
      $selectedLoadtypes: state => state.selectedLoadtypes,
      $selectedDate: state => state.selectedDate,
      $calendar: state => state.calendar,
      $viewType: state => state.viewType,
      $selectedEvent: state => state.selectedEvent,
      $mode: state => state.mode,
      $zoomLevel: state => state.zoomLevel,
      $selectedStatuses: state => state.selectedStatuses,
      $isFetchingEvents: state => state.isFetchingEvents,
      $areEventsReady: state => state.areEventsReady,
      $useLightGridTheme: state => state.useLightGridTheme,
      $intervalMinutes: state => state.intervalMinutes,
      $selectedWarehouseHoops: state => state.selectedWarehouseHoops,
      $shouldShowWeekends: state => state.shouldShowWeekends,
      $events: state => state.events,
      $eventPagination: state => state.eventPagination,
      $selectedWarehouseTriggers: state => state.selectedWarehouseTriggers
    }),
    ...mapState('LoadTypes', {
      $loadTypes: state => state.loadTypes,
      $loadTypeMap: state => state.loadTypeMap,
      $loadTypePagination: state => state.pagination
    }),
    ...mapState('Carriers', {
      $carriers: state => state.carriers,
      $carrierPagination: state => state.pagination
    }),
    ...mapState('App', {
      $appWarehouse: state => state.warehouse,
      $hasLoadType: state => state.hasLoadType,
      $hasDock: state => state.hasDock,
      $hasSchedule: state => state.hasSchedule,
      $hasAppointment: state => state.hasAppointment,
      $hasInvitedUser: state => state.hasInvitedUser,
      $allowedUserRoles: state => state.allowedUserRoles,
      $nonOnboardableRoles: state => state.nonOnboardableRoles,
      $areCoreStepsCompleted: state => state.areCoreStepsCompleted,
      $logoPath: state => state.logoPath,
      $neutronVersion: state => state.neutronVersion,
      $appEnvironment: state => state.appEnvironment,
      $mobileAttributes: state => state.mobile
    }),
    ...mapState('Websockets', {
      $websocketConnectionStatus: state => state.connectionStatus
    })
  }
};
