<template>
  <div class="mb-4 full-width" :class="{ 'd-flex flex-row align-center ': isInline }">
    <template v-if="readonly">
      <div>
        <div class="mt-3 font-size-x-small">Load Types</div>
        <div class="d-flex flex-wrap mt-3">
          <v-chip
            :color="'dark-gray'"
            v-for="(loadTypeId, index) in modelValue"
            :key="`email-${loadTypeId}`"
            class="mb-2"
            :class="{ 'mr-2': index + 1 < modelValue.length }">
            <span class="pr-2">{{ getLoadTypeNameById(loadTypeId) }}</span>
          </v-chip>
        </div>
      </div>
    </template>
    <template v-else>
      <h3 v-if="label" :class="disabled ? 'text--disabled' : ''" class="mr-3">
        {{ label }}:
        <help-icon-tooltip v-if="tooltipText">
          {{ tooltipText }}
        </help-icon-tooltip>
      </h3>
      <v-select
        data-testid="loadtype-select"
        class="loadtype-select"
        :loading="loading"
        :density="dense ? 'compact' : 'default'"
        :prepend-icon="!hideIcon ? 'mdi-dolly' : ''"
        :flat="flat"
        :solo="solo"
        hide-details="auto"
        @update:model-value="updateValue"
        :model-value="modelValue"
        :items="filteredLoadTypesByDock"
        item-title="name"
        item-value="id"
        ref="loadtypeselect"
        :placeholder="$attrs.placeholder"
        :persistent-placeholder="!!$attrs.placeholder"
        :label="customPersistentLabel ?? placeholder"
        :multiple="multiSelect"
        v-bind="{ ...$props, ...$attrs }"
        :return-object="returnObject"
        :disabled="disabled"
        :clearable="clearable"
        @click:clear="updateValue({})">
        <template #label>
          <slot name="label"></slot>
          <span v-if="required">
            {{ placeholder }}
            <span class="text-red"><strong>*</strong></span>
          </span>
        </template>

        <template #prepend-item v-if="selectableItems.length && multiSelect && selectAll">
          <v-list-item ripple @click="toggleSelectAllItems" :title="selectAllLabel">
            <template #prepend>
              <v-list-item-action>
                <v-icon class="mr-2" :color="modelValue?.length > 0 ? 'error darken-4' : ''">
                  {{ selectAllIcon }}
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-divider />
        </template>

        <template #item="{ item, props }">
          <slot name="item" :item="item">
            <div v-if="props.title.header">
              <v-list-subheader>
                {{ props.title.header }}
              </v-list-subheader>
              <v-divider></v-divider>
            </div>

            <v-list-item
              v-else
              :color="isItemSelected(item.raw) ? 'primary' : 'default'"
              v-bind="props"
              :title="item.title">
              <template #prepend="{ isSelected }" v-if="multiSelect">
                <v-icon class="mr-2" :color="isSelected ? 'primary' : 'default'">
                  {{ isSelected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                </v-icon>
              </template>
            </v-list-item>
          </slot>
        </template>

        <template #selection="{ item }" v-if="!multiSelect">
          <span>{{ getLoadTypeNameById(item.value) }}</span>
        </template>

        <template #selection="{ item, index }" v-if="multiSelect">
          <slot name="selection" :item="item" :index="index">
            <v-chip
              v-if="index < visibleSelectionCount && !filterMode"
              size="small"
              class="bg-primary"
              variant="flat"
              label>
              <span>{{ item.raw.name }}</span>
            </v-chip>
            <span v-else-if="index < visibleSelectionCount" small class="full-width">
              <span class="text-truncate">{{ item.name }}</span>
            </span>
            <div
              v-if="!filterMode && index === visibleSelectionCount"
              class="text-grey text-caption">
              (+{{ modelValue?.length - visibleSelectionCount }} other{{
                modelValue?.length === visibleSelectionCount + 1 ? '' : 's'
              }})
            </div>
            <div
              v-if="filterMode && (index === visibleSelectionCount || modelValue?.length === 1)"
              class="text-caption text-right selected-quantity"
              :class="selectionTextData.class">
              {{ selectionTextData.text }}
            </div>
          </slot>
        </template>

        <template v-slot:append-item v-if="appendCreateLoadTypeOnList">
          <v-divider></v-divider>
          <v-list-item>
            <span>Didn't find a load type?</span>
            <a class="ml-2" href="#" @click="showCreateDialog = true">Create Load Type</a>
          </v-list-item>
        </template>

        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              <div>
                <span class="d-block pt-2">
                  Looks like this dock doesn't have any load types yet...
                </span>
                <router-link
                  custom
                  v-slot="{ navigate }"
                  v-if="!appendCreateLoadTypeOnList && $org"
                  @click.native="$emit('close')"
                  :to="{
                    path: `/org/${$org.id}/loadtypes`,
                    props: true,
                    query: { showLoadTypeForm: true }
                  }">
                  <primary-button @click="navigate" class="mt-2">
                    Let's Create a load type
                  </primary-button>
                </router-link>
              </div>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </template>
    <create-load-type-dialog
      :warehouse="selectedWarehouse"
      @saved="handleCreateLoadType"
      @close="showCreateDialog = false"
      external-activator
      :model-value="showCreateDialog"></create-load-type-dialog>
  </div>
</template>

<script>
import selectMixin from '../../mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';

export default {
  mixins: [selectMixin, selectAllSelectMixin],
  props: {
    /**
     * @model
     */
    modelValue: {
      required: true
    },
    /**
     * Selected Warehouse
     */
    selectedWarehouse: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    },
    /**
     * Selected Dock
     */
    selectedDocks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * Disable the select
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Multiple values can be selected
     */
    multiSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Return an object from the select
     */
    returnObject: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Determines if duration should show next to load type name
     */
    includeDurations: {
      type: Boolean,
      required: false,
      default: true
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * The number of selected items to show before truncating
     */
    visibleSelectionCount: {
      type: Number,
      required: false,
      default: 1
    },
    /**
     * Tooltip text to show beside the label within the tooltip helper
     */
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: true
    },
    height: {
      type: String,
      required: false,
      default: '25px'
    },
    selectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    /*
     * This would send to the getMany docks API to filter the loadtypes that can be assigned to a specified dock
     */
    showOnlyAssignedLoadTypes: {
      type: Boolean,
      required: false,
      default: false
    },
    appendCreateLoadTypeOnList: {
      type: Boolean,
      default: false
    },
    filterMode: {
      type: Boolean,
      default: false
    },
    customPersistentLabel: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    showOrgLoadTypes: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedLoadType: null,
      placeholder: this.multiSelect ? 'Select Load Types' : 'Select Load Type',
      warehouseLoadTypes: [],
      orgLoadTypes: [],
      selectRefKey: 'loadtypeselect',
      filters: {
        limit: this.novaCore.MAX_LOAD_TYPES_PER_ORG,
        s: {},
        sort: 'name,ASC'
      },
      showCreateDialog: false
    };
  },
  computed: {
    selectableItems() {
      return this.filteredLoadTypesByDock.filter(l => Boolean(l.id)) || [];
    },
    selectionTextData() {
      const selected = this.modelValue?.length || 0;
      const total = this.selectableItems.length || 0;

      return {
        text: `${selected}/${total} Selected`,
        class: selected === total ? 'grey--text' : 'red--text'
      };
    },
    warehouseDocks() {
      return this.selectedWarehouse?.id ? this.selectedWarehouse.docks : [];
    },
    filteredLoadTypesByDock() {
      const loadTypesToFilter = (
        this.selectedWarehouse ? this.warehouseLoadTypes : this.orgLoadTypes
      )
        .filter(loadType => {
          const hasDocksSelected = this.selectedDocks?.length;
          // Show up non assigned docks when the filterMode is active
          if (!hasDocksSelected || this.filterMode) {
            return true;
          }

          let selectedDocksLoadTypeIds = [];

          this.selectedDocks.forEach(sd => {
            selectedDocksLoadTypeIds = [...selectedDocksLoadTypeIds, sd.loadTypeIds.flat()].flat();
          });

          return selectedDocksLoadTypeIds.includes(loadType.id);
        })
        .map(loadType => ({
          ...loadType,
          name: this.$loadTypeName(loadType, this.includeDurations)
        }));

      const orgGroupName = 'ORG LOAD TYPES';
      const locationGroupName = `${
        this.selectedWarehouse?.name?.toLocaleUpperCase() || 'LOCATION'
      } LOAD TYPES`;

      const warehouseLoadTypes = loadTypesToFilter
        .filter(l => Boolean(l.warehouseId))
        .map(l => ({
          ...l,
          Group: locationGroupName
        }));
      const orgLoadTypes = loadTypesToFilter
        .filter(l => !l.warehouseId)
        .map(l => ({
          ...l,
          Group: orgGroupName
        }));

      const items = [];

      if (warehouseLoadTypes?.length > 0) {
        items.push({ header: locationGroupName });
        items.push(...warehouseLoadTypes);
      }

      if (this.showOrgLoadTypes && orgLoadTypes?.length > 0) {
        items.push({ header: orgGroupName });
        items.push(...orgLoadTypes);
      }

      return items;
    }
  },
  methods: {
    // Overrides mixin function so we can return IDs here rather than full objects
    // TODO: Add functionality to do this in mixin?
    toggleSelectAllItems() {
      this.$nextTick(() => {
        if (this.allItemsSelected) {
          this.updateValue([]);
        } else {
          this.updateValue(this.selectableItems.map(item => item.id));
          this.$refs[this.selectRefKey].blur();
        }
      });
    },
    /**
     * v-model
     * @param loadTypes
     */
    updateValue(loadTypes) {
      this.$emit('update:modelValue', loadTypes);
    },
    /**
     * Fetch load types based on selected warehouse
     * @public
     * @returns {Promise<void>}
     */
    async getWarehouseLoadTypes() {
      this.warehouseLoadTypes = [];
      if (this.selectedWarehouse?.id) {
        const query = { ...this.filters };

        if (this.showOnlyAssignedLoadTypes) {
          query.showOnlyAssignedLoadTypes = true;
        }

        // warehouseId is an optional parameter on /loadtype, not part of the search string because it does not
        // live on the loadtype entity
        query.warehouseId = this.selectedWarehouse.id;

        const response = await axios.get('loadtype', {
          params: query
        });

        this.warehouseLoadTypes = response?.data?.data || [];
      }
    },
    /**
     * fetch all load types
     * @public
     * @returns {Promise<void>}
     */
    async getAllLoadTypes() {
      const query = { ...this.filters };
      const response = await axios.get('loadtype', {
        params: query
      });

      this.orgLoadTypes = response?.data?.data || [];
    },
    getLoadTypeNameById(loadTypeId) {
      const loadType = this.selectableItems.find(loadType => {
        return loadType.id === loadTypeId;
      });
      return loadType?.name ?? '';
    },
    async handleCreateLoadType() {
      await this.fetchData();
    },
    async fetchData() {
      this.selectedWarehouse?.id
        ? await this.getWarehouseLoadTypes()
        : await this.getAllLoadTypes();
    },
    async handleSocketLoadTypeUpdate() {
      // the full loadtype object is passed as argument
      // but, we download all load types to be sure
      return this.getWarehouseLoadTypes();
    },
    isItemSelected(item) {
      if (this.multiSelect) {
        return this.modelValue?.some?.(val => {
          return val.id === item.id;
        });
      } else {
        return this.modelValue?.id === item.id;
      }
    }
  },
  mounted() {
    this.$eventHub.$on('update-LoadType', this.handleSocketLoadTypeUpdate);
    this.$eventHub.$on('create-LoadType', this.handleSocketLoadTypeUpdate);
    this.$eventHub.$on('delete-LoadType', this.handleSocketLoadTypeUpdate);

    this.getWarehouseLoadTypes();
  },
  beforeUnmount() {
    this.$eventHub.$off('update-LoadType');
    this.$eventHub.$off('create-LoadType');
    this.$eventHub.$off('delete-LoadType');
  },
  watch: {
    selectedWarehouse(newValue, oldValue) {
      if (newValue?.id !== oldValue?.id) {
        if (this.filterMode) {
          this.updateValue([]);
        }
        if (newValue?.id) {
          this.getWarehouseLoadTypes();
        }
      }
    },
    selectedDocks(value, oldValue) {
      if (value?.id !== oldValue?.id) {
        this.getWarehouseLoadTypes();
      }
    },
    filteredLoadTypesByDock(value) {
      if (this.filterMode && this.modelValue?.length === 0 && value?.length > 0) {
        this.toggleSelectAllItems();
      }
    }
  }
};
</script>

<style scoped>
.selected-quantity {
  width: 150px;
  text-align: right;
}
</style>
