// @ts-check

/**
 * @typedef HeliosEnvVars
 * @type {object}
 * @property {string} ui_app_name
 * @property {string} server_env
 * @property {string} neutron_url
 * @property {string} subspace_url
 * @property {string} luna_url
 * @property {string} comet_url
 * @property {string} tc_last_update
 * @property {string} unleash_app_name
 * @property {string} unleash_url
 * @property {string} unleash_client_key
 * @property {string} [sentry_dsn]
 * @property {string} [sentry_traces_sample_rate]
 * @property {string} [hotjar_site_id]
 * @property {string} [mixpanel_token]
 * @property {string} [zendesk_key]
 * @property {string} [gtm_id]
 * @property {string} [beamer_product_id]
 * @property {string} [fullcalendar_key]
 */

import { DateTime } from 'luxon';

/**
 * @param {Partial<HeliosEnvVars>} env
 * @returns {string}
 */
function getUnleashUrl(env) {
  const { unleash_url, neutron_url } = env;
  const url = unleash_url ? unleash_url.trim() : null;
  if (url) return url;
  return new URL('/feature-flag/proxy', neutron_url).toString();
}

/**
 * @param {Partial<HeliosEnvVars>} env
 * @returns {string}
 */
function getUnleashClientKey(env) {
  const { unleash_client_key } = env;
  const key = unleash_client_key ? unleash_client_key.trim() : null;
  if (key) return key;
  const token =
    (sessionStorage?.getItem('access_token') || localStorage?.getItem('access_token')) ?? '';
  return `Bearer ${token || ''}`;
}

const formattedVars = /** @type {HeliosEnvVars} */ (
  Object.entries(import.meta.env).reduce(
    (env, [key, value]) => ({ ...env, [key.replace('VITE_', '').toLowerCase()]: value }),
    {}
  )
);

/** @type {HeliosEnvVars} */
const config = {
  ...formattedVars,
  ...{
    ui_app_name: 'Helios',
    application_name: 'Opendock Nova',
    unleash_app_name: 'opendock-nova',
    unleash_url: getUnleashUrl(formattedVars),
    unleash_client_key: getUnleashClientKey(formattedVars),
    terms_conditions_last_update: DateTime.fromISO(import.meta.env.VITE_TC_LAST_UPDATE)
  }
};

export default config;
