<template>
  <div class="flex-1">
    <v-autocomplete
      :class="{ required: field.required }"
      :model-value="field.value"
      :rules="validationRules"
      :items="field.dropDownValues"
      v-bind="{ ...$props, ...$attrs }"
      @update:model-value="value => $emit('input', value || '')"
      density="compact"
      clearable
      open-on-clear>
      <template #label>Choose an option</template>
    </v-autocomplete>
  </div>
</template>

<script>
import customFieldTypeMixinOld from '@/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  name: 'custom-field-dropdown-old',
  mixins: [customFieldTypeMixinOld]
};
</script>
