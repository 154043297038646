<template>
  <custom-field-input-base
    v-bind="{ ...$props, ...$attrs }"
    :field="field"
    v-model="value"
    type="email"></custom-field-input-base>
</template>

<script>
import customFieldTypeMixin from '@/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-email',
  mixins: [customFieldTypeMixin]
};
</script>
