<template>
  <div>
    <h4>Select the type of email notification you want to send out:</h4>
    <v-list
      slim
      density="compact"
      id="email-notifications-list"
      data-testid="email-notification-list">
      <template v-for="(item, key) in novaCore.Notifications" :key="key">
        <v-list-item density="compact" class="pl-0">
          <template #prepend>
            <v-checkbox
              :disabled="!entitySettings.muteAppointmentNotifications"
              :value="key"
              v-model="enabledAppointmentNotifications"
              :ripple="false"
              @click="handleMuteNotificationClick"
              class="mt-0"
              hide-details
              density="compact"
              color="primary">
              <template v-slot:label>
                <div class="ml-2">{{ item.title }}</div>
              </template>
            </v-checkbox>
          </template>

          <v-list-item-title>
            <span class="font-size-xx-small">
              {{
                item.UIRenderOptions && item.UIRenderOptions.description
                  ? `(${item.UIRenderOptions.description})`
                  : ''
              }}
            </span>
          </v-list-item-title>

          <template #append>
            <v-icon @click="$eventHub.$emit('set-edit-notification-key', item.key)">
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'EmailNotificationsList',
  props: {
    entitySettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      orgAppointmentsAreEnabled: null,
      localSettings: {
        mutedAppointmentNotifications: []
      },
      enabledAppointmentNotifications: []
    };
  },
  methods: {
    handleMuteNotificationClick() {
      this.$emit('settingsUpdated', this.localSettings);
    }
  },
  mounted() {
    this.localSettings = this.novaCore.deepClone(this.entitySettings);
    // NOTE: The UI is designed to show notifications that are ENABLED
    // But in our system we maintain a list of DISABLED/MUTED appointments
    // So here we reverse the logic
    this.enabledAppointmentNotifications = Object.keys(this.novaCore.Notifications).filter(
      key => !this.localSettings.mutedAppointmentNotifications?.includes(key)
    );
  },
  watch: {
    enabledAppointmentNotifications() {
      // Reversing enabled / muted logic as described in the mounted function
      this.localSettings.mutedAppointmentNotifications = Object.keys(
        this.novaCore.Notifications
      ).filter(key => !this.enabledAppointmentNotifications.includes(key));
    }
  }
};
</script>

<style scoped lang="scss">
#email-notifications-list .v-list-item:nth-child(even) {
  background: #e8e8e8;
}
</style>
