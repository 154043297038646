<template>
  <v-form>
    <v-card class="dialog-box">
      <v-card-title class="d-flex flex-row align-center">
        <span class="text-h5">Add new Carrier Contact</span>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon="mdi-close" @click="$emit('close')" />
      </v-card-title>
      <v-card-text class="dialog-body">
        <v-stepper v-model="step" flat class="px-8 pt-1 pb-2">
          <v-stepper-header class="elevation-2 rounded">
            <v-stepper-item :complete="step > 1" :value="1" color="primary">
              Enter Contact Info
            </v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item :complete="step > 2" :value="2" color="primary">
              Select Carrier Company
            </v-stepper-item>
          </v-stepper-header>

          <v-stepper-window>
            <v-stepper-window-item class="px-0" :value="1">
              <form-base :header="header" hide-required-legend>
                <template #form>
                  <v-form ref="stepOneForm" @keyup.enter="submit">
                    <v-container class="py-0">
                      <v-row>
                        <v-col>
                          <h3>
                            <v-icon color="grey-darken-2" class="pr-3">
                              mdi-card-account-details-outline
                            </v-icon>
                            First enter your Carrier Contact info
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2">
                        <v-col>
                          <text-field
                            :required="true"
                            type="text"
                            density="compact"
                            v-model="fields.firstName"
                            :rules="$validator.rules.required('First Name')"
                            label="First Name"></text-field>
                        </v-col>
                        <v-col>
                          <text-field
                            :required="true"
                            type="text"
                            density="compact"
                            v-model="fields.lastName"
                            :rules="$validator.rules.required('Last Name')"
                            label="Last Name"></text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <text-field
                            :required="true"
                            type="text"
                            v-model="fields.email"
                            name="email"
                            density="compact"
                            :rules="[
                              ...$validator.rules.required('Email'),
                              ...$validator.rules.email
                            ]"
                            :error-messages="$validator.get('email')"
                            hint="Email must be unique"
                            persistent-hint
                            label="Email"></text-field>
                        </v-col>

                        <v-col>
                          <text-field
                            type="text"
                            density="compact"
                            v-model="fields.phone"
                            :rules="$validator.rules.phone"
                            label="Phone Number"></text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-checkbox
                            :class="{ 'text--disabled': isWarehouseRestricted }"
                            class="org-favorite-checkbox ml-0 shrink"
                            color="primary"
                            v-model="fields.isOrgFavorite"
                            label="Favorite this contact for every warehouse, including future ones"
                            data-testid="create-carrier-favorite"
                            :disabled="isWarehouseRestricted" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </template>
              </form-base>
            </v-stepper-window-item>
            <v-stepper-window-item class="px-0" :value="2">
              <form-base :header="header" hide-required-legend>
                <template #form>
                  <v-form ref="stepTwoForm" @keyup.enter="submit">
                    <v-row>
                      <v-col>
                        <h3>
                          <v-icon color="grey-darken-2">mdi-domain</v-icon>
                          Now select the Carrier Company:
                        </h3>
                      </v-col>
                    </v-row>
                    <v-row class="pt-4">
                      <v-col>
                        <company-select
                          :entry-point="entryPoint"
                          v-model="fields.company"
                          ref="companySelect"
                          placeholder="Search for a company name, scac, usdot or mc..."
                          :rules="$validator.rules.required('Carrier Company')"
                          hide-icon
                          required></company-select>
                      </v-col>
                    </v-row>
                  </v-form>
                </template>
              </form-base>
            </v-stepper-window-item>
          </v-stepper-window>
          <v-alert color="amber-lighten-5" class="mx-1">
            <p class="mb-1">
              <v-icon size="small" color="amber-darken-3" class="mr-2">mdi-information</v-icon>
              <strong>This carrier will receive appointment email notifications if enabled.</strong>
            </p>

            <p class="mt-1 mb-0 ml-7">
              To schedule a test appointment, please use an email domain ending in “@example.com”.
            </p>
          </v-alert>
        </v-stepper>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="dialog-actions">
        <action-group
          :confirm-label="step === 1 ? 'next' : 'Add Carrier Contact'"
          @confirm="step === 1 ? submitStepOne() : submitStepTwo()"
          @cancel="$emit('close')"
          :cancel-icon="null"
          :disable-confirm="step === 1 ? !isStepOneComplete : !isStepTwoComplete">
          <template #additional-actions>
            <v-btn v-if="step !== 1" @click="step = 1">Previous</v-btn>
          </template>
        </action-group>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
/**
 * Create Carrier Form
 * @displayName Create Carrier Form
 */
import useAuth from '@/composables/useAuth';

export default {
  setup() {
    const { isWarehouseRestricted } = useAuth();

    return { isWarehouseRestricted: isWarehouseRestricted.value };
  },
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    },
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fields: {},
      step: 1
    };
  },
  computed: {
    isStepOneComplete() {
      return this.fields.firstName && this.fields.lastName && this.fields.email;
    },
    isStepTwoComplete() {
      return this.fields.company;
    }
  },
  methods: {
    cancel() {
      /**
       * Emits close event
       * @event close
       */
      this.$emit('close');
    },
    updateCompany(company) {
      this.fields.company = company;
    },
    async submitStepOne() {
      this.$validator.clear();
      if (!(await this.$isFormValid(this.$refs.stepOneForm))) {
        return;
      }

      const emailAvailable = await axios.get(`user/email-available/${this.fields.email}/`);

      if (!emailAvailable?.data) {
        this.$validator.add({
          field: 'email',
          msg: 'This email is already in use'
        });
      } else {
        this.step = 2;
      }
    },
    mixpanelTrack(event, entryPoint = null) {
      this.mixpanel.track(event, {
        'Entry Point': entryPoint || this.entryPoint,
        'Carrier Contact Name': `${this.fields.firstName} ${this.fields.lastName}`,
        'Carrier Contact Email': this.fields.email,
        'Carrier Company ID': this.fields.company.id,
        'Carrier Company Name': this.fields.company.name,
        'Org Name': this.$org.name,
        'Org ID': this.$org.id
      });
    },
    async submitStepTwo() {
      if (!(await this.$isFormValid(this.$refs.stepOneForm))) {
        return;
      }

      const payload = {
        ...this.fields,
        orgCarrierSettings: {
          emailCCs: null,
          favoriteWarehouseIds: this.fields.isOrgFavorite ? null : []
        },
        companyId: this.fields.company.id
      };

      try {
        const response = await this.$store.dispatch('Carriers/createCarrier', payload);
        if (!response?.data) {
          throw new Error('Missing response data');
        }

        this.mixpanelTrack(this.mixpanel.events.MODULE.CARRIER.CREATED);

        if (this.fields.isOrgFavorite) {
          this.mixpanelTrack(this.mixpanel.events.MODULE.CARRIER.FAVORITES.ADDED);
        }
        this.$emit('save', response.data.data);
        this.$emit('close');
      } catch (error) {
        console.error('Failed to create carrier', error);
      }
    },
    async submit() {
      if (this.step === 1) {
        this.submitStepOne();
      } else {
        this.submitStepTwo();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.org-favorite-checkbox.text--disabled > :deep(.v-input__control > .v-input__slot > .v-label) {
  color: $color-text-disabled !important;
}

.org-favorite-checkbox {
  :deep(.v-label) {
    font-weight: 400 !important;
  }
}
</style>
