import * as novaCore from '@nova/core';
import satelliteAppStore from '@satellite/store/app.js';

// initial state
const state = {
  ...satelliteAppStore.state,
  allowedUserRoles: [novaCore.UserRole.OWNER, novaCore.UserRole.ADMIN],
  hasAppointment: false,
  hasDock: false,
  hasInvitedUser: false,
  hasLoadType: false,
  hasSchedule: true,
  nonOnboardableRoles: [novaCore.UserRole.GOD, novaCore.UserRole.INTERNAL],
  warehouse: null,
  org: null,
  logoPath: '/images/opendock_loadsmart_logo.png'
};

// getters
const getters = {
  ...satelliteAppStore.getters
};

// actions
const actions = {
  ...satelliteAppStore.actions
};

// mutations
const mutations = {
  ...satelliteAppStore.mutations,
  setHasAppointment(state, value) {
    state.hasAppointment = value;
  },
  setHasDock(state, value) {
    state.hasDock = value;
  },
  setHasInvitedUser(state, value) {
    state.hasInvitedUser = value;
  },
  setHasLoadType(state, value) {
    state.hasLoadType = value;
  },
  setHasSchedule(state, value) {
    state.hasSchedule = value;
  },
  setWarehouse(state, value) {
    state.warehouse = value;
  },
  setOrg(state, value) {
    state.org = value;
  }
};

export default {
  namespaced: true,
  name: 'App',
  state,
  getters,
  actions,
  mutations
};
