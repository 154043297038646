<template>
  <dialog-base
    no-padding
    :key="renderKey"
    tile
    :loading="false"
    v-bind="{ ...$props, ...$attrs }"
    header=""
    hide-close-icon
    loading-message=""
    body-class="mobile-nav-dialog-body"
    @close="close"
    max-width="100%"
    :value="showDialog"
    width="980px">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button v-on="slotProps.on" v-bind="slotProps.attrs">View Appointment</primary-button>
    </template>
    <template v-slot:body>
      <div class="pa-4 d-flex align-center justify-space-between">
        <v-btn icon class="disabled-bg" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <mobile-opendock-logo />
      </div>
      <div class="my-4 d-flex flex-column align-center justify-center text-white px-4">
        <strong class="is-uppercase">{{ userName }}</strong>
        <span class="font-size-x-small">{{ userRole }}</span>
      </div>
      <navigation-drawer-mobile :org-required="true" @close="close"></navigation-drawer-mobile>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import NavigationDrawerMobile from '@/components/elements/mobile/NavigationDrawerMobile.vue';
/**
 * Nav dialog for mobile
 * @displayName Nav Dialog Mobile
 */
export default {
  components: { NavigationDrawerMobile },
  mixins: [dialogMixin],
  computed: {
    userName() {
      return `${this.$me?.firstName} ${this.$me?.lastName}`;
    },
    userRole() {
      return this.novaCore.upperFirst(this.$me?.role?.replace('role_', ''));
    }
  },
  watch: {
    showDialog(newVal) {
      if (newVal) {
        this.$store.commit('App/setMobilePageTitle', 'Appointment Details');
      } else {
        this.$store.commit('App/setMobilePageTitle', this.$route.meta.title);
      }
    }
  }
};
</script>
