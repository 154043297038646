<template>
  <div class="flex-grow-1">
    <template v-if="readOnly">
      <div class="mt-3 font-size-x-small">Tags</div>
      <div class="d-flex flex-wrap mt-3">
        <v-chip
          :color="'dark-gray'"
          v-for="(tag, index) in modelValue"
          :key="`tag-${tag}`"
          class="mb-2"
          :class="{ 'mr-2': index + 1 < modelValue.length }">
          <span class="pr-2">{{ tag }}</span>
        </v-chip>
      </div>
    </template>
    <v-combobox
      v-else
      variant="outlined"
      :key="renderKey"
      :multiple="multiple"
      :model-value="modelValue"
      :delimiters="[',']"
      @update:model-value="emitInput"
      :hide-no-data="false"
      :label="label"
      v-bind="{ ...$attrs, ...$props }"
      :items="customTags"
      hide-selected
      item-title="name"
      item-value="name"
      :return-object="false"
      :chips="hasChips"
      persistent-placeholder
      :hide-details="hideInstructions"
      :placeholder="placeholder"
      class="tag-input stack-selections label-text">
      <template #chip="{ item }">
        <template v-if="!hideSelectedTags">
          <v-chip
            v-bind="$attrs"
            :color="tagColor"
            label
            :variant="outlinedChips ? 'outlined' : undefined"
            v-if="!util.getCustomTagData($org.customTags, item.raw?.name)">
            <span class="pr-2">{{ item.raw }}</span>
            <v-icon size="small" v-if="allowTagDelete" @click="removeTag(item.raw)">
              mdi-close
            </v-icon>
          </v-chip>
          <v-chip
            v-bind="$attrs"
            :color="util.getCustomTagData($org.customTags, item.raw.name).color"
            :style="{ color: util.getCustomTagData($org.customTags, item.raw.name).textColor }"
            label
            v-else>
            <span class="pr-2">
              {{ util.getCustomTagData($org.customTags, item.raw.name).name }}
            </span>
            <v-icon size="small" v-if="allowTagDelete" @click="removeTag(item.raw.name)">
              mdi-close
            </v-icon>
          </v-chip>
        </template>
      </template>

      <template #no-data>
        <v-list-item class="input-instructions"><span v-html="instructions" /></v-list-item>
      </template>

      <template #item="{ props, item }">
        <v-list-item v-bind="props" :title="null">
          <v-chip :color="`${item.raw.color}`" :style="{ color: `${item.raw.textColor}` }" label>
            {{ item.raw.name }}
          </v-chip>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import TagManagerBase from '@satellite/components/elements/TagManagerBase.vue';
/**
 * @displayName Tag Manager
 */
export default {
  extends: TagManagerBase
};
</script>
