<template>
  <v-autocomplete
    :class="{ required: field.required }"
    :model-value="field.value"
    :rules="validationRules"
    :items="field.dropDownValues"
    v-bind="{ ...$props, ...$attrs }"
    @update:model-value="value => $emit('input', value || '')"
    density="compact"
    clearable
    open-on-clear
    hide-details="auto"
    :placeholder="field.label"
    :persistent-placeholder="persistentPlaceholder ? Boolean(field.placeholder) : false"
    multiple>
    <template #label>Choose one or more options</template>
  </v-autocomplete>
</template>

<script>
import customFieldTypeMixin from '@/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  mixins: [customFieldTypeMixin]
};
</script>
