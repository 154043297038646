<template>
  <v-dialog
    max-width="560px"
    width="50%"
    v-model="isOpen"
    @keydown.escape="closeDialog"
    v-bind="$attrs">
    <v-card class="dialog-box" data-testid="create-company-dialog">
      <v-card-title class="d-flex flex-row align-center">
        <span class="text-h5">New Carrier Company</span>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon="mdi-close" @click="closeDialog" />
      </v-card-title>
      <v-card-text class="dialog-body">
        <v-form ref="form" @keyup.enter="createCarrierCompany">
          <text-field
            :required="true"
            type="text"
            v-model="fields.name"
            :rules="$validator.rules.required('Company Name')"
            label="Company Name"></text-field>
          <text-field
            type="text"
            v-model="fields.scac"
            label="SCAC code"
            :rules="$validator.rules.optionalLengthBetween(2, 4)"></text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions">
        <v-btn
          variant="text"
          @click="closeDialog"
          data-testid="carrier-contact-remove-dialog-action-nevermind">
          Nevermind
        </v-btn>
        <primary-button
          @click="createCarrierCompany"
          data-testid="carrier-contact-remove-dialog-action-remove">
          Save Company
        </primary-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { get } from 'lodash';
import { useEventBasedDialog, useMixpanel, useOrg } from '@/composables';
import { isFormValid } from '@satellite/plugins/util.js';

export default defineComponent({
  name: 'EventTriggeredCreateCarrierCompanyDialog',
  setup() {
    const store = useStore();
    const mixpanel = useMixpanel();
    const { orgRef: org } = useOrg();
    const { isOpen, dialogProps, closeDialog } = useEventBasedDialog({
      eventName: 'open-create-carrier-company-dialog'
    });

    const form = ref(null);

    const fields = ref({
      name: '',
      scac: ''
    });

    // Reset fields when dialog closes
    watch(isOpen, newValue => {
      if (!newValue) {
        fields.value = {
          name: '',
          scac: ''
        };
        if (form.value) {
          form.value.reset();
        }
      }
    });

    const mixpanelEntryPoint = computed(() => dialogProps.value?.mixpanelEntryPoint ?? true);
    const onCompanyCreated = computed(() => dialogProps.value?.onCompanyCreated);

    const createCarrierCompany = async () => {
      if (!dialogProps.value) {
        console.error('Failed to create company: No dialog props found');
        return;
      }

      if (!(await isFormValid(form.value))) {
        return;
      }

      try {
        const response = await store.dispatch('Companies/createCompany', fields.value);
        const data = get(response, 'data.data', null);

        if (data) {
          mixpanel.track(mixpanel.events.MODULE.COMPANY.CREATED, {
            'Entry Point': mixpanelEntryPoint.value,
            'Carrier Company Name': fields.value.name,
            'SCAC Code': fields.value.scac,
            'Org Name': org.value.name,
            'Org ID': org.value.id
          });

          onCompanyCreated.value?.(data);
          closeDialog();
        }
      } catch (error) {
        console.error('Failed to create company', error);
      }
    };

    return {
      fields,
      form,
      isOpen,
      closeDialog,
      createCarrierCompany
    };
  }
});
</script>
