// SELECT ALL TEMPLATE EXAMPLE
// <template v-slot:prepend-item v-if="warehouses.length && multiSelect">
//   <v-list-item ripple @click="toggleSelectAllItems">
//   <v-list-item-action>
//     <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ icon }}</v-icon>
// </v-list-item-action>
// <v-list-item-content>
//   <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
// </v-list-item-content>
// </v-list-item>
// <v-divider slot="prepend-item" class="mt-2" />
// </template>

/**
 * Mixin for adding "select all" functionality to select comps
 * @mixin
 * @displayName Select All Select Mixin
 */
// !! IMPORTANT !!
// In order to use this mixin you must:
// 1. Define a "selectableItems" computed property which returns the selectable items in your select comp
// 2. Define a selectRefKey data property which matches the ref property that you have put on your select field
// 3. Define an "updateValue" method which will set or emit the selected item
export default {
  props: {
    autoSelectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    disableSelectAll: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      // !! IMPORTANT !! Overwrite this in your select comp!
      selectRefKey: null,
      _hasSetInitialData: false
    };
  },
  computed: {
    // !! IMPORTANT !! Overwrite this in your select comp!
    selectableItems() {
      return [];
    },

    allItemsSelected() {
      return this.modelValue?.length === this.selectableItems.filter(i => !i.header).length;
    },
    someItemsSelected() {
      return this.modelValue?.length > 0 && !this.allItemsSelected;
    },
    selectAllLabel() {
      let label = 'Select All';
      if (this.allItemsSelected) {
        label = 'Deselect All';
      }
      return label;
    },
    selectAllIcon() {
      let icon = 'mdi-checkbox-blank-outline';
      if (this.allItemsSelected) {
        icon = 'mdi-close-box';
      }
      if (this.someItemsSelected) {
        icon = 'mdi-plus-box';
      }
      return icon;
    }
  },
  methods: {
    // !! IMPORTANT !! Overwrite this updateValue in your select comp!
    updateValue(val) {
      this.$emit('update:modelValue', val || []);
    },
    toggleSelectAllItems() {
      this.$nextTick(() => {
        if (this.allItemsSelected) {
          this.updateValue([]);
        } else {
          this.selectAllItems();
        }
        this.$emit('change');
      });
    },
    selectAllItems() {
      this.updateValue(this.selectableItems);
    },
    _autoSelectAll() {
      if (
        this.autoSelectAll &&
        !this.modelValue?.length &&
        !this._hasSetInitialData &&
        this.selectableItems?.length
      ) {
        this.updateValue(this.selectableItems);
        this._hasSetInitialData = true;
      }
    }
  },
  watch: {
    selectableItems() {
      this._autoSelectAll();
    }
  },
  mounted() {
    this._autoSelectAll();
  }
};
