import { ref, onMounted, onUnmounted, watch } from 'vue';

export type CustomEventMap = {
  /**
   * list here all the custom events that are used in the app
   * so the IDE's type inference helps us when emitting the events.
   */
  'open-remove-carrier-contact-dialog': CustomEvent<OpenRemoveCarrierContactDialogEvent>;
  'open-create-carrier-company-dialog': CustomEvent<OpenCreateCarrierCompanyDialogEvent>;
  'open-create-carrier-contact-dialog': CustomEvent<OpenCreateCarrierContactDialogEvent>;
};

/**
 * Type of the event listener for custom events.
 */
export type CustomEventListener<K extends keyof CustomEventMap> = Parameters<
  typeof document.addEventListener<K>
>[1];

export function subscribeToCustomEvent<K extends keyof CustomEventMap>(
  eventName: K,
  listener: CustomEventListener<K>,
  target: EventTarget = document
) {
  target.addEventListener(eventName, listener);
}

export function unsubscribeFromCustomEvent<K extends keyof CustomEventMap>(
  eventName: K,
  listener: CustomEventListener<K>,
  target: EventTarget = document
) {
  target.removeEventListener(eventName, listener);
}

export function emitCustomEvent<K extends keyof CustomEventMap>(
  eventName: K,
  target: EventTarget
): void;
export function emitCustomEvent<K extends keyof CustomEventMap>(
  eventName: K,
  data: CustomEventMap[K]['detail'],
  target?: EventTarget
): void;
export function emitCustomEvent<K extends keyof CustomEventMap>(
  eventName: K,
  dataOrTarget?: CustomEventMap[K]['detail'] | EventTarget,
  maybeTarget?: EventTarget
) {
  const target = maybeTarget ?? (dataOrTarget instanceof EventTarget ? dataOrTarget : document);
  const data = dataOrTarget instanceof EventTarget ? undefined : dataOrTarget;

  const event = new CustomEvent(eventName, { detail: data });
  target.dispatchEvent(event);
}

type OpenRemoveCarrierContactDialogEvent = {
  carrierContact: {
    id: string;
    isFavorite: boolean;
    emailCCs: string[];
    company?: {
      name: string;
    };
    user: {
      company?: {
        name: string;
      };
      firstName?: string;
      lastName?: string;
      fullName?: string;
    };
  };
  mixpanelData?: unknown;
  onContactRemoved?: () => void;
};

type OpenCreateCarrierCompanyDialogEvent = {
  mixpanelEntryPoint: string;
  // TODO: add the proper types for the Company object
  onCompanyCreated?: (company: unknown) => void;
};

type OpenCreateCarrierContactDialogEvent = {
  mixpanelEntryPoint: string;
  // TODO: add the proper types for the Carrier object
  onContactCreated?: (contact: unknown) => void;
};

declare global {
  interface DocumentEventMap extends CustomEventMap {}
}
