import config from '@/config/config';

/**
 * Get the Authorization header for the feature flag client.
 * @returns {string}
 */
export function getAuthHeader() {
  const key = 'access_token';
  const token = sessionStorage?.getItem(key) || localStorage?.getItem(key);
  return `Bearer ${token || ''}`;
}

/**
 * Custom fetch for the feature flag client.
 * It will inject the current authorization header for the user instead
 * of using an static value.
 * @param {string | URL} url
 * @param {RequestInit} init
 * @returns {Response}
 */
function customFetch(url, init) {
  init = init || {};
  init.headers = init.headers || {};
  init.headers.Authorization = getAuthHeader();
  init.headers['X-UI-AppName'] = config.ui_app_name;
  return fetch(url, init);
}

export default {
  url: config.unleash_url,
  appName: config.unleash_app_name,
  clientKey: getAuthHeader(),
  environment: config.server_env === 'production' ? 'production' : 'development',
  fetch: customFetch,
  disableMetrics: true,
  usePOSTrequests: true
};
