<template>
  <div>
    <div class="d-flex align-center">
      <v-icon size="x-small" color="primary" class="pr-1">mdi-check</v-icon>
      <span class="font-size-x-small font-weight-bold text-black">{{ title }}</span>
    </div>
    <div class="font-size-x-small text-dense">{{ data }}</div>
  </div>
</template>

<script>
export default {
  name: 'SummaryItem',
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: String,
      required: true
    }
  }
};
</script>
