<template>
  <form-base :teleport-target="dialogTeleportTarget">
    <template #form>
      <v-form ref="form">
        <v-container>
          <text-field
            :required="true"
            :type="currentPasswordFieldType"
            v-model="currentPassword"
            :rules="$validator.rules.required('Current password')"
            label="Current Password"
            :append-icon="iconShowCurrentPassword"
            @click:append="toggleShowCurrentPassword()"></text-field>
          <text-field
            :required="true"
            :type="passwordFieldType"
            :rules="$validator.rules.password"
            :append-icon="iconShowPassword"
            @click:append="toggleShowPassword()"
            v-model="newPassword"
            label="New Password"></text-field>

          <password-restrictions :password="newPassword"></password-restrictions>
        </v-container>
      </v-form>
    </template>
    <template #post-field-content>
      <div class="my-6">
        <p class="text-center small">
          You will be asked to login again after changing the password!
        </p>
      </div>
    </template>
    <template #form-actions>
      <action-group
        @cancel="$emit('close')"
        confirm-icon="upload"
        confirm-label="Change Password"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
import { inject } from 'vue';
export default {
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      showPassword: false,
      showCurrentPassword: false
    };
  },
  methods: {
    async submit() {
      if (!(await this.$isFormValid(this.$refs.form))) {
        return;
      }

      this.$store
        .dispatch('Users/changeCurrentUserPassword', {
          newPassword: this.newPassword,
          currentPassword: this.currentPassword
        })
        .then(() => this.$emit('close'));
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    toggleShowCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
    }
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    iconShowPassword() {
      return this.showPassword ? 'mdi-eye-off' : 'mdi-eye';
    },
    currentPasswordFieldType() {
      return this.showCurrentPassword ? 'text' : 'password';
    },
    iconShowCurrentPassword() {
      return this.showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye';
    }
  },
  setup() {
    const dialogTeleportTarget = inject('dialogActionsRef');
    return { dialogTeleportTarget };
  }
};
</script>
