import { cloneDeep } from 'lodash';

export default {
  props: {
    getAllFavorites: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    getCarrierName(carrier) {
      return `${carrier.firstName} ${carrier.lastName}`;
    },
    getSelectedCarrierText(carrier, truncateLimit, truncateAsWhole) {
      const truncate = this.novaCore.truncateString;
      if (!truncateAsWhole) {
        return `${truncate(this.getCarrierName(carrier), truncateLimit)} -
        ${truncate(carrier.company?.name, truncateLimit)} -
        ${truncate(carrier.email, truncateLimit)}`;
      } else {
        return truncate(
          `${this.getCarrierName(carrier)} -
        ${carrier.company?.name ?? 'Warehouse User'} -
        ${carrier.email}`,
          truncateLimit
        );
      }
    },
    getCarrierEmailCCs(carrier) {
      return carrier.orgCarrierSettings?.length > 0
        ? carrier.orgCarrierSettings[0]?.emailCCs || []
        : [];
    },
    isCarrierFavorite(carrier) {
      if (!carrier.orgCarrierSettings || !carrier.orgCarrierSettings[0]) {
        return false;
      }

      if (carrier.orgCarrierSettings[0].favoriteWarehouseIds === null) {
        // Means it is an org-level favorite
        return true;
      }

      return (
        this.warehouse &&
        carrier.orgCarrierSettings[0].favoriteWarehouseIds.includes(this.warehouse.id)
      );
    },
    async getData() {
      this.isLoading = true;
      const query = this.getQuery(!this.filters.searchStr);

      // Search by email subscribers per org
      // The join is made on the backend
      if (query.s.$or && query.searchStr) {
        query.s.$or.push({
          '"orgCarrierSettings"."emailCCs"': { $contL: query.searchStr }
        });
      }
      let carriers = [];
      // The purpose of this flag is to ensure the carrier select always contains all of the favorites
      // Previously favorites could fall outside of the total limit (15), and so they would not be part of the dataset
      // So we get all the favorites first, and then get the rest (up to the limit) if the limit is not met
      // We could pass "onlyIncludeFavorites" to the call, but that only accounts for favorites -
      // where the warehouse is null (applied to all warehouses)
      // So we won't get favorites where the current WH ID is in the list of favorites
      // Instead, we get carriers where favoriteWarehouseIds is null or the current WH ID is in the list
      // Alternatively we could create or modify the call in the API to include a flag to get all favorites without -
      // a limit, which would prevent having to make two calls here
      // For now, we will handle this on the frontend
      if (this.getAllFavorites) {
        const favoritesParams = { ...cloneDeep(query), ...{ limit: 100 } };
        favoritesParams.s.$and = favoritesParams.s.$and ?? [];
        favoritesParams.s.$and.push({ '"orgCarrierSettings"."orgId"': this.$org.id });
        favoritesParams.s.$and.push({
          $or: [
            { '"orgCarrierSettings"."favoriteWarehouseIds"': { $eq: null } },
            ...(this.warehouse?.id
              ? [{ '"orgCarrierSettings"."favoriteWarehouseIds"': { $contL: this.warehouse.id } }]
              : [])
          ]
        });

        // If searching, we need to make sure the search query is ANDED with the favorites query, not ORed
        if (query.searchStr) {
          favoritesParams.s.$and.push({
            $or: favoritesParams.s.$or
          });
          delete favoritesParams.s.$or;
          // If we have a searchStr, the search ORs need to be ANDED with the $notin query below
          query.s.$and = query.s.$and ?? [];
          query.s.$and.push({
            $or: query.s.$or
          });
          delete query.s.$or;
        }

        const favoriteCarriersRes = await axios.get('carrier', {
          params: favoritesParams
        });
        if (favoriteCarriersRes?.data?.data) {
          carriers = favoriteCarriersRes.data.data;
        }
      }

      if (carriers.length < query.limit) {
        if (carriers.length) {
          query.s.$and.push({ id: { $notin: carriers.map(c => c.id) } });
          query.limit = query.limit - carriers.length;
        }

        const res = await axios.get('carrier', {
          params: query
        });
        if (res?.data?.data) {
          carriers = [...carriers, ...res.data.data];
        }
      }
      this.carriers = carriers;

      this.sortCarriers();

      this.isLoading = false;
    },
    sortCarriers() {
      const rankMap = {};

      this.carriers.forEach(c => {
        let rank = 0;

        if (this.filters.searchStr) {
          const searchPieces = this.filters.searchStr.toLowerCase().split(' ');
          const lowerSearch = this.filters.searchStr.toLowerCase();
          const fullName = `${c.firstName} ${c.lastName}`.toLowerCase();

          // Full name or email match
          if (fullName === lowerSearch || c.email.toLowerCase() === lowerSearch) {
            rank += 3;
          }

          searchPieces.forEach(searchPiece => {
            rank += fullName.indexOf(searchPiece) > -1 ? 2 : 0;
          });
        }

        // Is fav
        rank += this.isCarrierFavorite(c) ? 1 : 0;

        rankMap[c.id] = rank;
      });

      this.carriers.sort((a, b) => (rankMap[a.id] < rankMap[b.id] ? 1 : -1));
    },
    getQuery(contactsOnly = false) {
      const query = this.getQueryBase();

      if (contactsOnly) {
        query.s.$and = [
          {
            '"orgCarrierSettings"."orgId"': this.$org.id
          }
        ];
      }

      if (this.filters.searchStr) {
        query.limit = '50';
      }
      return query;
    }
  }
};
