<template>
  <div class="is-relative">
    <hoc-dialog-loader :loading="loading" :loading-message="loadingMessage">
      <template v-slot:default>
        <v-container v-if="header">
          <h2>{{ header }}</h2>
        </v-container>
        <div class="form-wrapper">
          <!-- @slot The Form -->
          <slot name="form"></slot>
          <small class="pl-5" v-if="!hideRequiredLegend" :class="requiredLegendClass">
            * indicates required field
          </small>
        </div>
        <slot name="post-field-content"></slot>
      </template>
      <template #form-actions>
        <teleport defer :to="teleportTarget" v-if="$slots['form-actions'] && teleportTarget">
          <!-- @slot Form Actions -->
          <slot name="form-actions"></slot>
        </teleport>
        <slot v-else name="form-actions"></slot>
      </template>
    </hoc-dialog-loader>
  </div>
</template>

<script>
import HocDialogLoader from '@satellite/components/elements/loaders/HocDialogLoader';
/**
 * Base form skeleton used for quick builds of new forms
 * @displayName Form Base
 */
export default {
  components: { HocDialogLoader },
  props: {
    /**
     * Form header
     */
    header: {
      type: String,
      required: false
    },
    /**
     * Hide the "* fields are required" text
     */
    hideRequiredLegend: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Loading message for ajax forms
     */
    loadingMessage: {
      type: String,
      required: false,
      default: 'Loading...'
    },
    requiredLegendClass: {
      type: String,
      required: false,
      default: ''
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    useGlobalLoader: {
      type: Boolean,
      required: false,
      default: true
    },
    teleportTarget: {
      type: HTMLElement,
      required: false,
      default: null
    }
  },
  computed: {
    loading() {
      const isGlobalLoading = this.useGlobalLoader ? this.$data.$globalLoading : false;
      const isLoading = typeof this.isLoading !== 'undefined' ? this.isLoading : isGlobalLoading;
      return isLoading;
    }
  }
};
</script>
<style lang="scss" scoped>
.actions {
  background-color: $white;
  z-index: 2;
}
</style>
