<template>
  <text-field
    dense
    :required="field.required"
    v-model="value"
    v-bind="{ ...$props, ...$attrs }"
    :prepend-icon="fieldIcon"
    @keydown="handleKeydown"
    :hide-details="hideDetails ? 'auto' : false"
    :label="displayLabel ? field.label : ''"
    :placeholder="field.placeholder ?? field.label"
    :persistent-placeholder="persistentPlaceholder ? Boolean(field.placeholder) : false"
    :rules="validationRules"></text-field>
</template>

<script>
import customFieldTypeMixin from '@/modules/custom_fields/mixins/customFieldTypeMixin';
export default {
  mixins: [customFieldTypeMixin],
  methods: {
    handleKeydown(e) {
      if (
        this.novaCore.CustomFieldType[this.novaCore.upperFirst(this.fieldType)] ===
        this.novaCore.CustomFieldType.Number
      ) {
        if (this.disallowedNumberCharacters.includes(e.key)) {
          e.preventDefault();
        }
      }
    }
  }
};
</script>
