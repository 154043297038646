import { mapGetters } from 'vuex';

/**
 * Store getters map
 * @mixin
 * @displayName Globally Mapped Getters
 */
export default {
  computed: {
    ...mapGetters({
      $companiesKeyedById: 'Companies/keyedById',
      $selectedDockIds: 'Calendar/selectedDockIds',
      $warehouseEventsByDock: 'Calendar/warehouseEventsByDock',
      $docksKeyedById: 'Calendar/docksKeyedById',
      $docksByWarehouseId: 'Docks/docksByWarehouseId',
      $dockById: 'Calendar/dockById',
      $loadTypesByIdArray: 'LoadTypes/loadTypesByIdArray',
      $usersByRole: 'Users/usersByRole',
      $loadTypeName: 'LoadTypes/loadTypeName',
      $getSettingValue: 'Settings/settingValue',
      $refNumSettings: 'Settings/refNumSettings',
      $isMilitaryTimeEnabled: 'Settings/isMilitaryTimeEnabled',
      $isRescheduleByCarrierForbidden: 'Settings/isRescheduleByCarrierForbidden',
      $appointmentJoins: 'Appointments/appointmentJoins',
      // PERMISSIONS
      $isWarehouseReadOnly: 'Auth/isWarehouseReadOnly',
      $isLoadTypeReadOnly: 'Auth/isLoadTypeReadOnly',
      $isDockReadOnly: 'Auth/isDockReadOnly',
      $isOrgReadOnly: 'Auth/isOrgReadOnly'
    })
  }
};
