<template>
  <v-radio-group
    v-model="field.value"
    :rules="validationRules"
    @update:model-value="$emit('input', field.value)"
    class="mb-2"
    :class="{ required: field.required }">
    <div v-for="feature of field.dropDownValues" :key="feature" class="mb-2">
      <div class="d-flex flex-row">
        <v-radio class="mt-0" :value="feature" :id="feature" />
        <div class="mx-2">
          <p class="mb-0">
            <label :for="feature">
              <strong>{{ featureData(feature).title }}</strong>
            </label>
          </p>
          <p>
            <small>{{ featureData(feature).description }}</small>
          </p>
        </div>
      </div>
    </div>
  </v-radio-group>
</template>

<script>
import customFieldTypeMixinOld from '@/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  name: 'custom-field-action',
  mixins: [customFieldTypeMixinOld],
  methods: {
    featureData(feature) {
      return this.novaCore.CustomFormsFeaturesData[feature];
    }
  }
};
</script>
