<script>
import renderMixin from '@satellite/components/mixins/renderMixin';
/**
 * @displayName Tag Manager Base
 */
export default {
  mixins: [renderMixin],
  props: {
    /**
     * @model
     */
    modelValue: {
      type: [Array, String],
      required: false,
      default: () => []
    },
    /**
     * Field Label
     */
    label: {
      type: String,
      required: false
    },
    /**
     * Color for tags
     */
    tagColor: {
      type: String,
      required: false,
      default: 'secondary'
    },
    /**
     * Allow deletion of individual tag
     */
    allowTagDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Field instructions
     */
    instructions: {
      type: String,
      required: false,
      default: 'Press <kbd>enter</kbd> or <kbd>tab</kbd> to create tag'
    },
    /**
     * Outline the chips
     */
    outlinedChips: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldEnableCustomTags: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Add tags'
    },
    hideInstructions: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSelectedTags: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true
    },
    rerenderInputOnSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      customTags: []
    };
  },
  computed: {
    hasChips() {
      return !!this.modelValue?.length;
    }
  },
  methods: {
    /**
     * Remove a tag
     * @public
     * @param tag
     */
    removeTag(tag) {
      this.$emit(
        'update:model-value',
        this.modelValue.filter(item => item !== tag)
      );
    },
    emitInput(val) {
      this.$emit('update:model-value', val);
      if (this.rerenderInputOnSelect) {
        this.renderKey++;
      }
    }
  },
  mounted() {
    if (this.shouldEnableCustomTags) {
      this.customTags = this.novaCore.deepClone(this.$org.customTags) ?? [];
    }
  }
};
</script>
