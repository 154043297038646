<template>
  <div class="time-selector">
    <div class="date-header">
      <section class="align-center justify-center action-container">
        <div class="d-flex flex-1 justify-space-between date-nav align-center">
          <v-btn variant="text" @click="previousDates" class="nav-btn">
            <v-icon size="large">mdi-chevron-left</v-icon>
          </v-btn>
          <div class="d-flex align-center">
            <date-picker
              v-model="selectedDate"
              icon-mode
              :open-dates="openDates"
              input-min-width="300px"
              @date-navigation="setOpenDates">
              <template #icon-mode-text></template>
              <template #icon-trigger="{ openDatePicker }">
                <div class="text-center cursor-pointer" @click="openDatePicker">
                  <v-icon size="small" class="mr-2">mdi-calendar</v-icon>
                  <span class="text-caption text-uppercase font-weight-bold">
                    {{ dateTimeHeader }}
                  </span>
                </div>
              </template>
            </date-picker>
          </div>
          <v-btn variant="text" @click="nextDates" class="nav-btn">
            <v-icon size="large">mdi-chevron-right</v-icon>
          </v-btn>
          <dock-select
            variant="outlined"
            :display-checkboxes="false"
            label="Dock"
            hide-icon
            :clearable="false"
            :multi-select="false"
            :docks="docks"
            data-testid="reschedule-dialog-dock-select"
            v-if="isReschedule && allowDockSelect"
            class="dock-select"
            v-model="selectedDock"></dock-select>
        </div>
      </section>

      <div class="d-flex date-labels mt-4">
        <div
          v-for="item in dateRange"
          :key="`slot-${item.date}-header`"
          class="text-center time-column">
          <h3>
            <span class="d-block">{{ item.day }}</span>
            {{ item.date }}
          </h3>
        </div>
      </div>
    </div>
    <div class="time-grid d-flex mb-4 container">
      <template v-if="!processing">
        <div
          v-for="item in dateRange"
          :key="`slot-${item.date}-times`"
          class="text-center time-column d-flex flex-column align-center">
          <template v-if="availability[item.key]?.length > 0">
            <template v-for="(slot, i) in availability[item.key]">
              <dst-divider v-if="slot.isDSTChange" :key="`${i}-divider`" />
              <secondary-button
                v-if="isEqual(modelValue?.start, slot.start)"
                :key="`${i}-time-active`"
                class="time-button"
                height="29"
                :data-date-time="
                  formatTime(
                    slot.start.toISO(),
                    novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeNoSpace
                  )
                "
                @click="setSlot(slot)">
                {{ formatTime(slot.start.toISO()) }}
              </secondary-button>
              <outline-button
                v-else
                :key="`${i}-time`"
                class="time-button"
                height="29"
                :data-date-time="
                  formatTime(
                    slot.start.toISO(),
                    novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeNoSpace
                  )
                "
                :class="{ 'time-button-past': slot.isPast }"
                @click="setSlot(slot)">
                {{ formatTime(slot.start.toISO()) }}
              </outline-button>
            </template>
          </template>
          <template v-else>
            <div>No availability</div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="loader full-width pb-8">
          <v-progress-linear
            indeterminate
            :loading="processing"
            height="6"
            class="mt-12"></v-progress-linear>
          <h4 class="text-center mt-4">Loading Availability...</h4>
        </div>
      </template>
    </div>

    <load-type-re-select-dialog
      return-load-type
      v-if="appointment && selectedDock && allowDockSelect"
      @close="handleLoadTypeSelectDialogClose"
      :original-event="appointment"
      :event="appointment"
      @cancel="nevermindLoadTypeDialog"
      :event-warehouse="appointment.dock.warehouse"
      :event-dock="selectedDock"
      :original-event-dock="appointment.dock"
      :show-dialog="shouldShowLoadTypeReselectDialog"
      @update-selected-load-type="updateSelectedLoadType"></load-type-re-select-dialog>
  </div>
</template>

<script>
import { DateTimeFormats } from '@nova/core';
import moment from 'moment-timezone';
import { computed, onMounted, ref, watch } from 'vue';
import { useNovaCore } from '@/composables';
import { useStore } from 'vuex';
import { DateTime } from 'luxon';
import { isEqual } from 'lodash';

/**
 * Date Time picker that uses availability from API based on existing appointment or create appointment form data
 * @displayName Date Time Picker
 */
export default {
  name: 'DateTimePicker',
  methods: { isEqual },
  props: {
    selectedWarehouse: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    /**
     * The Selected Dock
     */
    selectedDocks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * The Selected Load Type
     */
    selectedLoadType: {
      type: Object,
      required: true
    },
    /**
     * The Selected Start Date
     */
    startDate: {
      type: String,
      required: false,
      default: moment().format(DateTimeFormats.DateDashed)
    },
    /**
     * @model
     */
    modelValue: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * Warehouse timezone
     */
    timezone: {
      type: String,
      required: true
    },
    existingStart: {
      type: String,
      required: false
    },
    existingEnd: {
      type: String,
      required: false
    },
    numDays: {
      type: Number,
      required: false,
      default: 3
    },
    appointment: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    mixin: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    isReschedule: {
      type: Boolean,
      required: false,
      default: false
    },
    allowDockSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['input', 'update-selected-dock', 'update-selected-loadtype', 'update:modelValue'],
  setup(props, context) {
    const novaCore = useNovaCore();
    const store = useStore();
    const availability = ref({});
    const dateRange = ref([]);
    const processing = ref(true);
    const startOfDateRange = ref(null);
    const selectedSlot = ref(null);
    const openDates = ref(null);
    const selectedDate = ref('');
    const selectedDock = ref(null);
    const shouldShowLoadTypeReselectDialog = ref(false);
    const newSelectedDock = ref(null);

    const docks = computed(() => props.selectedWarehouse.docks ?? []);
    const loading = computed(() => Boolean(processing.value));
    const dateTimeHeader = computed(() => {
      let header = '';
      if (dateRange.value?.length) {
        const headerFormat = novaCore.LuxonDateTimeFormats.ShortMonthDayYear;
        const start = DateTime.fromISO(dateRange.value[0].iso)
          .setZone(props.timezone)
          .toFormat(headerFormat);
        const end = DateTime.fromISO(dateRange.value[dateRange.value.length - 1].iso)
          .setZone(props.timezone)
          .toFormat(headerFormat);
        header = `${start} - ${end}`;
      }
      return header;
    });

    const nevermindLoadTypeDialog = () => {
      shouldShowLoadTypeReselectDialog.value = false;
      selectedDock.value = props.appointment.dock;
      newSelectedDock.value = null;
    };

    const formatTime = (time, format) => {
      const tz = null;
      return novaCore.formatDateTimeWithMilitarySupport(
        time,
        tz,
        format ?? novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        store.getters['Settings/isMilitaryTimeEnabled'](
          props.appointment?.id ? props.appointment.dock.warehouse : props.selectedWarehouse
        ),
        format ?? novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    };

    const initializeProcessAvailabilityVars = () => {
      processing.value = true;
      let i = 0;
      const avail = {};
      return {
        avail,
        i
      };
    };

    const autoSelectStartTime = () => {
      if (!props.existingStart && props.modelValue?.start) {
        selectedSlot.value = isTimeAvailable() ? props.modelValue : {};
      }
    };

    const sortSlots = () => {
      Object.entries(availability.value).map(([key, value]) => {
        availability.value[key] = value.sort((a, b) => a.start.toJSDate() - b.start.toJSDate());
      });
    };

    const processAvailability = availabilityData => {
      let { avail, i } = initializeProcessAvailabilityVars();
      const now = DateTime.now().setZone(props.timezone);
      let dstChangeDetected = false;
      while (i < availabilityData.length) {
        const item = availabilityData[i];
        let prevZoneName;
        let curZoneName;

        item.startTimes.forEach(startTime => {
          const availabilityStartDate = novaCore.formatDateTimeWithMilitarySupport(
            startTime,
            props.timezone,
            novaCore.LuxonDateTimeFormats.MonthDayYear
          );

          const startDateTime = DateTime.fromISO(startTime).setZone(props.timezone);

          curZoneName = DateTime.fromISO(startTime).setZone(props.timezone).toFormat('ZZZZ');

          const slotData = {
            start: startDateTime,
            docks: [],
            isPast: startDateTime < now,
            isDSTChange: false
          };
          if (prevZoneName && curZoneName !== prevZoneName) {
            if (!dstChangeDetected) {
              slotData.isDSTChange = true;
            }
            dstChangeDetected = true;
          }

          if (!novaCore.objPropExists(avail, availabilityStartDate)) {
            avail[availabilityStartDate] = [];
          }

          const existingAvailabilityItemIndex = avail[availabilityStartDate].findIndex(
            availItem => availItem.start?.ts === slotData.start?.ts
          );

          if (existingAvailabilityItemIndex < 0) {
            slotData.docks.push(item.dock.id);
            avail[availabilityStartDate].push(slotData);
          } else {
            avail[availabilityStartDate][existingAvailabilityItemIndex].docks.push(item.dock.id);
          }
          prevZoneName = curZoneName;
        });
        i++;
      }

      availability.value = avail;
      autoSelectStartTime();
      sortSlots();
      processing.value = false;
    };

    const previousDates = async () => {
      startOfDateRange.value = startOfDateRange.value.minus({ days: props.numDays });
      await getAvailability();
    };

    const nextDates = async () => {
      startOfDateRange.value = startOfDateRange.value.plus({ days: props.numDays });
      await getAvailability();
    };

    const getAvailability = async () => {
      processing.value = true;
      createDateRange();
      let params = {
        warehouseId: props.selectedWarehouse.id,
        includeStartTimes: true,
        start: startOfDateRange.value.startOf('day').toISO(),
        end: startOfDateRange.value.plus({ days: props.numDays }).endOf('day').toISO()
      };
      if (props.appointment?.id) {
        params.excludeApptId = props.appointment.id;
      }
      const response = await store.dispatch('LoadTypes/getAvailability', {
        id: props.selectedLoadType.id,
        params
      });
      if (response?.data?.data) {
        const selectedDockIds =
          props.selectedDocks?.length > 0
            ? props.selectedDocks.map(dock => dock.id)
            : props.selectedWarehouse.docks.map(dock => dock.id);

        const dockAvailabilities = response.data.data.filter(item => {
          return selectedDockIds.includes(item.dock.id);
        });

        processAvailability(dockAvailabilities);
      }
    };

    const initializeSetOpenDatesVars = () => {
      openDates.value = null;
      const docks = props.selectedDocks;
      return { docks };
    };

    const setOpenDates = async date => {
      const { docks } = initializeSetOpenDatesVars();
      if (date && docks?.length > 0) {
        const start = DateTime.fromJSDate(new Date(date))
          .setZone(props.timezone)
          .startOf('month')
          .toJSDate();
        const end = DateTime.fromJSDate(new Date(date))
          .setZone(props.timezone)
          .endOf('month')
          .toJSDate();
        const dockIds = props.selectedDocks.map(d => d.id);

        const response = await window.axios.post(`dock/compute-open-dates`, {
          start,
          end,
          dockIds
        });

        if (response?.data?.data) {
          openDates.value = response.data.data.openDates;
        }
      }
    };

    const createDateRange = () => {
      let range = [];
      let i = 0;
      while (i < props.numDays) {
        range.push({
          iso: startOfDateRange.value.plus({ days: i }).toISO(),
          day: startOfDateRange.value
            .plus({ days: i })
            .toFormat(novaCore.LuxonDateTimeFormats.ShortWeekday),
          date: startOfDateRange.value
            .plus({ days: i })
            .toFormat(novaCore.LuxonDateTimeFormats.MonthDayYearSlashed),
          key: startOfDateRange.value
            .plus({ days: i })
            .toFormat(novaCore.LuxonDateTimeFormats.MonthDayYear)
        });
        i++;
      }
      dateRange.value = range;
    };

    const setSlot = slot => {
      selectedSlot.value = slot;
    };

    const isTimeAvailable = () => {
      const comparisonFormat = novaCore.LuxonDateTimeFormats.Extended24HrTimeLeadingZeroHourSeconds;
      const selectedTimeStr = props.modelValue.start.toFormat(comparisonFormat);
      const checkDate = startOfDateRange.value.toFormat(novaCore.LuxonDateTimeFormats.MonthDayYear);
      const dateAvailability =
        availability.value[checkDate]?.filter(slot => {
          return slot.start.toFormat(comparisonFormat) === selectedTimeStr;
        }) || [];
      return Boolean(dateAvailability.length);
    };

    const setStartOfDateRange = value => {
      startOfDateRange.value = DateTime.fromISO(value).setZone(props.timezone).startOf('day');
    };

    const updateSelectedLoadType = newLoadType => {
      context.emit('update-selected-dock', newSelectedDock.value);
      context.emit('update-selected-loadtype', newLoadType);
    };

    const handleLoadTypeSelectDialogClose = () => {
      shouldShowLoadTypeReselectDialog.value = false;
    };

    onMounted(async () => {
      startOfDateRange.value = DateTime.now().setZone(props.timezone).startOf('day');
      selectedDate.value = props.modelValue?.start
        ? props.modelValue.start.setZone(props.timezone).toISO()
        : props.startDate || DateTime.now().setZone(props.timezone).toISO();

      setStartOfDateRange(selectedDate.value);
      if (props.allowDockSelect) {
        selectedDock.value = props.appointment.dock;
      }
    });

    watch(selectedDate, async newDate => {
      setStartOfDateRange(newDate);
      await getAvailability();
    });

    watch(
      () => props.selectedDocks,
      async (newVal, oldVal) => {
        if (
          newVal.length !== oldVal.length ||
          newVal
            .map(dock => dock.id)
            .sort()
            .toString() !==
            oldVal
              .map(dock => dock.id)
              .sort()
              .toString()
        ) {
          await getAvailability();
        }
      }
    );

    watch(
      () => props.allowDockSelect,
      () => {
        if (props.allowDockSelect) {
          selectedDock.value = props.appointment.dock;
        }
      }
    );

    watch(
      () => selectedDock,
      newVal => {
        if (newVal && newVal.id !== this.appointment?.dockId) {
          this.selectedTime = null;
          this.$emit('update:modelValue', this.selectedTime);
        }
      }
    );

    watch(selectedDock, dock => {
      if (!dock?.id) {
        return;
      }
      if (
        dock?.loadTypeIds?.length > 0 &&
        !dock.loadTypeIds.includes(props.appointment.loadTypeId)
      ) {
        shouldShowLoadTypeReselectDialog.value = true;
        newSelectedDock.value = dock;
      } else {
        context.emit('update-selected-dock', dock);
        context.emit('update-selected-loadtype', props.appointment.loadType);
      }

      if (dock?.id !== props.appointment?.dockId) {
        selectedSlot.value = null;
        context.emit('input', selectedSlot);
      }
    });

    watch(selectedSlot, value => {
      context.emit('update:modelValue', value);
    });

    return {
      availability,
      dateRange,
      processing,
      startOfDateRange,
      selectedSlot,
      openDates,
      selectedDate,
      selectedDock,
      shouldShowLoadTypeReselectDialog,
      newSelectedDock,
      docks,
      loading,
      dateTimeHeader,
      nevermindLoadTypeDialog,
      formatTime,
      initializeProcessAvailabilityVars,
      autoSelectStartTime,
      sortSlots,
      processAvailability,
      previousDates,
      nextDates,
      getAvailability,
      initializeSetOpenDatesVars,
      setOpenDates,
      createDateRange,
      setSlot,
      isTimeAvailable,
      setStartOfDateRange,
      updateSelectedLoadType,
      handleLoadTypeSelectDialogClose
    };
  }
};
</script>

<style scoped lang="scss">
.time-selector {
  margin-bottom: 0;
}

.time-grid,
.date-header {
  padding: 0;
  margin: 0;
}

.time-grid,
.date-labels {
  max-width: 722px;
  margin: auto;
}

.date-labels {
  margin-top: 0;
  border-top: none;
  margin-top: 0 !important;
  padding-top: 24px;
}

.action-container {
  border-bottom: 1px solid $color-line-divider;
  padding: 12px 0;
}

.nav-btn {
  min-width: 36px !important;
  width: 36px;
  border-radius: 50%;
}

.trigger-btn {
  min-width: 36px !important;
  padding: 12px;
}

.caption {
  color: $color-text-primary;
  font-size: 14px;
  font-weight: 600;
}

.dock-select {
  width: 300px !important;
  margin-bottom: 0 !important;
  margin-left: 24px;
}

:deep(.dock-select) h3 {
  font-size: 16px !important;
  color: $color-text-tertiary;
}

:deep(.dock-select.v-input) {
  margin-top: 0;
  max-width: 270px;
}
</style>
